import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "home internal-container",
  style: {"width":"100%","height":"100%"}
}
const _hoisted_2 = {
  class: "pa-4 layout-container",
  style: {"height":"calc(100% - 128px)"},
  fluid: ""
}
const _hoisted_3 = { style: {"height":"100%","overflow-y":"auto"} }
const _hoisted_4 = {
  class: "row",
  style: {"height":"100%"}
}
const _hoisted_5 = {
  class: "col",
  style: {"height":"100%"}
}
const _hoisted_6 = { class: "row full-width" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-6 q-pr-md" }
const _hoisted_9 = { class: "col-6" }
const _hoisted_10 = { class: "col-6" }
const _hoisted_11 = { class: "col-6" }
const _hoisted_12 = { class: "col-6" }
const _hoisted_13 = { class: "col-6" }
const _hoisted_14 = { class: "col-6" }
const _hoisted_15 = {
  key: 0,
  class: "col-12"
}
const _hoisted_16 = {
  key: 0,
  class: "row"
}
const _hoisted_17 = { class: "col" }
const _hoisted_18 = { class: "text-subtitle-2" }
const _hoisted_19 = {
  key: 1,
  class: "row"
}
const _hoisted_20 = { class: "col" }
const _hoisted_21 = { class: "text-subtitle-2" }
const _hoisted_22 = { class: "col" }
const _hoisted_23 = { class: "text-subtitle-1" }
const _hoisted_24 = { style: {"text-align":"end"} }

import { isLoading$ } from '@gz/api';
import { useCustomerStore } from '@/store/customer';
import { BaseService } from '@/services/BaseService';
import { SlotItem } from 'greenzonegateway.classes/lib/classes/Generic/interfaces/SlotItem';
import { CustomerStatusEnum } from 'greenzonegateway.classes/lib/classes/customer/enums/CustomerStatus';
import {
	Customer,
	CustomerDetailsView,
	ICustomer,
} from 'greenzonegateway.classes/lib/classes/customer/index';
import { MenuItem } from 'greenzonegateway.classes/lib/classes';
import CustomersSearchFilter from './Components/CustomersSearchFilter.vue';
import {
	getCurrentInstance,
	nextTick,
	onBeforeUnmount,
	onMounted,
	Ref,
	ref,
	watch,
	computed,
} from 'vue';
import { useRouter } from 'vue-router';
import {
	GZDraggableModal,
	GZIndicator,
	GZSlideout,
	GZLabelText,
	GZPagination,
} from '@gz/quasar-components-vue3';
import TDMultiline from '@/components/TDMultiline.vue';
import debounce from 'quasar/src/utils/debounce/debounce.js';;


export default /*@__PURE__*/_defineComponent({
  __name: 'ViewCustomers',
  setup(__props) {

function nameof<T>(name: Extract<keyof T, string>): string {
	return name;
}

const createCustomerModal = ref<typeof GZDraggableModal>();

// eslint-disable-next-line @typescript-eslint/no-var-requires
const _customerStore = useCustomerStore();
const _instance = getCurrentInstance();
const _service = new BaseService();
const _router = useRouter();
const _loadingSubscription = ref();
const _isLoading = ref(true);

// selectedCustomers
const _customerStatusEnum = CustomerStatusEnum;
const _customers: Ref<CustomerDetailsView[]> = ref([]);
const _customersBackup: Ref<CustomerDetailsView[]> = ref([]);
const _selectedCustomers: Ref<CustomerDetailsView[]> = ref([]);
const _selectedCustomer: Ref<ICustomer> = ref(new Customer());

const _createCustomer = ref<any>({});
const _showCreateCustomer = ref(false);
const _isCreateCustomerValid = ref(false);
const _isExtra = ref(false);
const _isSlideoutOpen = ref(false);

const _fab = ref(false);
const _columns = ref([
	{
		name: 'id',
		label: 'No.',
		align: 'left',

		field: nameof<CustomerDetailsView>('CustomerId'),
		style: 'width: 8%',
	},
	{
		name: 'name',
		label: 'Name',
		field: 'CustomerName',
		align: 'left',
		style: 'width: 30%',
	},
	{
		name: 'liveServices',
		label: 'Live Services',
		field: 'LiveServices',
		align: 'left',
		style: 'width: auto',
	},
	{
		name: 'liveSites',
		label: 'Live Sites',
		field: 'LiveSites',
		align: 'left',
		style: 'width: auto',
	},
	{
		name: 'paymentMethod',
		label: 'Payment Method',
		field: 'Method',
		align: 'left',
		style: 'width: auto',
	},
]);
const _pagination = ref({
	sortBy: 'desc',
	descending: false,
	page: 1,
	rowsPerPage: 20,
});

const _slots: Ref<SlotItem[]> = ref([
	{
		Name: 'CustomerName',
		Line1: 'CustomerName',
		Line2: 'Group',
		Line3: 'Town',
		Line4: 'Postcode',
	},
]);

const _menuItems: Ref<MenuItem[]> = ref([
	{
		Name: 'Edit',
		Callback: Edit,
		ShowCount: true,
		Disabled: false,
		Confirm: () => '',
	},
	{
		Name: 'View Sites',
		Callback: NavigateToSites,
		ShowCount: true,
		Disabled: false,
		Confirm: () => '',
	},
	{
		Name: 'View Quotes',
		Callback: NavigateToQuotes,
		ShowCount: false,
		Disabled: false,
		Confirm: () => '',
	},
	{
		Name: 'Manage Suspended Services',
		Callback: NavigateToSuspendedServices,
		ShowCount: true,
		Disabled: false,
		Confirm: () => '',
	},
]);

watch(_selectedCustomers, (newValue: CustomerDetailsView[]) => {
	let item = null;

	if (newValue.length > 1) {
		item = _menuItems.value.find((xx) => xx.Name === 'Edit');

		if (item) {
			item.Disabled = true;
		}
	} else {
		item = _menuItems.value.find((xx) => xx.Name === 'Edit');

		if (item) {
			item.Disabled = false;
		}
	}
});

watch(_customerStore.getCustomersView, (current: CustomerDetailsView[]) => {
	_customers.value = current;
	_customersBackup.value = current;
});

onMounted(async () => {
	_loadingSubscription.value = await isLoading$.subscribe(
		(isLoading: boolean) => {
			_isLoading.value = isLoading;
		}
	);
	_customerStore.loadInitialCustomersList();

	var customers = _customerStore.getCustomersView;

	_customers.value = customers;
	_customersBackup.value = customers;

	nextTick(() => {
		_instance?.proxy?.$forceUpdate();
	});
});

function ResetSelected() {
	_selectedCustomers.value = [];
}

function GetCustomer(customer: CustomerDetailsView) {
	_service
		.Get<ICustomer>(`Customers/Details/${customer.CustomerId}`)
		.then((customer) => {
			nextTick(() => {
				_instance?.proxy?.$forceUpdate();
				_selectedCustomer.value = customer;
			});
			_isSlideoutOpen.value = true;
		});
}

function GetStatusIndicatorColour(item: CustomerDetailsView) {
	if (Object.keys(item).length === 0) {
		return '';
	} else if (item.CustomerStatus.toLowerCase().includes('live')) {
		return 'var(--q-positive)';
	} else if (item.CustomerStatus.toLowerCase().includes('stop')) {
		return 'var(--q-negative)';
	} else if (item.CustomerStatus.toLowerCase().includes('pending')) {
		return 'var(--q-warning)';
	} else if (item.CustomerStatus.toLowerCase().includes('termin')) {
		return 'var(--q-negative-darken2)';
	}
}

function FilterCustomers(search: string) {
	if (typeof search === 'object') {
		_customers.value = _customersBackup.value;
		return;
	}
	_customers.value = _customersBackup.value.filter(
		(xx) =>
			xx.AccountNumber?.toLowerCase().includes(search.toLowerCase()) ||
			xx.CustomerName?.toLowerCase().includes(search.toLowerCase()) ||
			xx.ContractStart?.toString()
				.toLowerCase()
				.includes(search.toLowerCase()) ||
			xx.ContractEnd?.toString().toLowerCase().includes(search.toLowerCase()) ||
			xx.Postcode?.toLowerCase().includes(search.toLowerCase()) ||
			xx.Town?.toLowerCase().includes(search.toLowerCase()) ||
			xx.CustomerId?.toString().includes(search) ||
			xx.Method?.toLowerCase().includes(search.toLowerCase()) ||
			xx.LiveSites?.toString().includes(search) ||
			xx.LiveServices?.toString().includes(search)
	);
}

function Edit() {
	Navigate('Edit Customer Details', null, {
		customerId: _selectedCustomers.value[0].CustomerId,
	});
}

function Navigate(
	name: string,
	query: any = null,
	params: any = null,
	isExternal = false
): void {
	if (!isExternal) {
		_router.push({
			name: name,
			query: query,
			params: params,
		});
	} else {
		_router.push({ path: name, query: query });
	}
}
function NavigateToSites() {
	Navigate('Sites', null, {
		customerId: _selectedCustomers.value[0]?.CustomerId,
	});
}
function NavigateToQuotes() {
	_router.push(`customers/${_selectedCustomers.value[0]?.CustomerId}/quotes`);
}
function NavigateToSuspendedServices() {
	Navigate('SuspendedServices', null, {
		customerId: _selectedCustomers.value[0]?.CustomerId,
	});
}

function CreateCustomer() {
	_service
		.Post<any>('Customers/Create', _createCustomer)
		.then((customerId: any) => {
			_showCreateCustomer.value = false;
			_router.push(`/customers/${customerId}/Edit/CustomerDetails`);
		});
}

onBeforeUnmount(() => {
	if (_loadingSubscription) {
		_loadingSubscription.value.unsubscribe();
	}
});

return (_ctx: any,_cache: any) => {
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_fab_action = _resolveComponent("q-fab-action")!
  const _component_q_fab = _resolveComponent("q-fab")!
  const _component_q_page_sticky = _resolveComponent("q-page-sticky")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_scroll_area = _resolveComponent("q-scroll-area")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_field = _resolveComponent("q-field")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_card = _resolveComponent("q-card")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_toolbar, { class: "bg-accent text-white q-pl-none" }, {
      default: _withCtx(() => [
        _createVNode(_component_q_toolbar_title, { style: {"padding-left":"16px"} }, {
          default: _withCtx(() => _cache[17] || (_cache[17] = [
            _createTextVNode("All Customers")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_q_page_sticky, {
          position: "bottom-right",
          style: {"margin":"8px !important","z-index":"1001"},
          offset: [24, 24]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_fab, {
              color: "primary",
              modelValue: _fab.value,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_fab).value = $event)),
              "vertical-actions-align": "left",
              direction: "up"
            }, {
              "active-icon": _withCtx(() => [
                _createVNode(_component_q_icon, {
                  style: {"padding":"none !important"},
                  name: "mdi-close"
                })
              ]),
              icon: _withCtx(() => [
                _createVNode(_component_q_icon, {
                  class: "q-pa-none",
                  name: "mdi-dots-vertical"
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_q_fab_action, {
                  "external-label": "",
                  "label-position": "left",
                  color: "secondary",
                  icon: "mdi-account-plus-outline",
                  label: "Create",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (createCustomerModal.value?.show()))
                }),
                _createVNode(_component_q_fab_action, {
                  "external-label": "",
                  "label-position": "left",
                  color: "secondary",
                  icon: "mdi-key-plus",
                  label: "Create",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (Navigate(`WebAccess`, null, null)))
                }),
                _createVNode(_component_q_fab_action, {
                  "external-label": "",
                  "label-position": "left",
                  color: "secondary",
                  icon: "mdi-cash",
                  label: "Quotes",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (Navigate(`Quotes`, null, null)))
                }),
                _createVNode(_component_q_fab_action, {
                  "external-label": "",
                  "label-position": "left",
                  color: "secondary",
                  icon: "mdi-note-text",
                  label: "Waste Transfer Notes",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (Navigate(`WasteTransferNotes`, null, null)))
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_unref(GZSlideout), {
              modelValue: _isSlideoutOpen.value,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_isSlideoutOpen).value = $event)),
              breakpoint: 900,
              overlay: false,
              drawerWidth: "450",
              pageContainerStyle: { height: 'calc(100% - 58px)' },
              "layout-style": { height: '100%', minHeight: '0px' }
            }, {
              menu: _withCtx(() => [
                _createVNode(_component_q_toolbar, {
                  class: "bg-accent",
                  style: {"height":"32px"}
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_q_btn, {
                        flat: "",
                        dense: "",
                        class: "q-ma-sm",
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_isSlideoutOpen.value = false))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_icon, {
                            name: "mdi-chevron-double-right",
                            color: "white"
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_toolbar_title, { style: {"padding-top":"8px","color":"white"} }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_selectedCustomer.value?.CustomerName), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_scroll_area, {
                  style: {"height":"80%"},
                  class: "q-pa-md",
                  id: "side-panel-container"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_unref(GZLabelText), {
                          label: 'Contact Name',
                          text: 
													`${_selectedCustomer.value?.Customer?.Title || ''} ${
														_selectedCustomer.value?.Customer?.FirstName || ''
													} ${
														_selectedCustomer.value?.Customer?.LastName || ''
													}`.trim() || 'N/A'
												
                        }, null, 8, ["text"])
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_unref(GZLabelText), {
                          class: "q-mb-md",
                          label: 'Telephone',
                          text: `${
													_selectedCustomer.value?.CustomerAddress?.Telephone || 'N/A'
												}`
                        }, null, 8, ["text"])
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        _createVNode(_unref(GZLabelText), {
                          class: "q-mb-md q-pr-md",
                          label: 'Email',
                          text: `${
													_selectedCustomer.value?.CustomerAddress?.Email || 'N/A'
												}`
                        }, null, 8, ["text"])
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_unref(GZLabelText), {
                          class: "q-mb-md",
                          label: 'Mobile',
                          text: `${
													_selectedCustomer.value?.CustomerAddress?.Mobile || 'N/A'
												}`
                        }, null, 8, ["text"])
                      ]),
                      _createElementVNode("div", _hoisted_12, [
                        _createVNode(_unref(GZLabelText), {
                          class: "q-mb-md q-pr-md",
                          label: 'Payment Method',
                          text: `${
													_selectedCustomer.value?.PaymentMethod?.Name || 'N/A'
												}`
                        }, null, 8, ["text"])
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _createVNode(_unref(GZLabelText), {
                          class: "q-mb-md",
                          label: 'Account Manager',
                          text: `${_selectedCustomer.value?.AccountManager || 'N/A'}`
                        }, null, 8, ["text"])
                      ]),
                      _createElementVNode("div", _hoisted_14, [
                        _createVNode(_unref(GZLabelText), {
                          class: "q-mb-md q-pr-md",
                          label: 'Payment Terms',
                          text: `${_selectedCustomer.value?.InvoiceTerms} Days`
                        }, null, 8, ["text"])
                      ]),
                      (_selectedCustomer.value.CustomerAddress)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                            _createVNode(_unref(GZLabelText), {
                              label: 'Address',
                              text: _selectedCustomer.value.CustomerAddress.AddressLine1
                            }, null, 8, ["text"]),
                            (_selectedCustomer.value.CustomerAddress.Town)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                  _createElementVNode("div", _hoisted_17, [
                                    _createElementVNode("span", _hoisted_18, _toDisplayString(_selectedCustomer.value.CustomerAddress.Town), 1)
                                  ])
                                ]))
                              : _createCommentVNode("", true),
                            (_selectedCustomer.value.CustomerAddress.Postcode)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                  _createElementVNode("div", _hoisted_20, [
                                    _createElementVNode("span", _hoisted_21, _toDisplayString(_selectedCustomer.value.CustomerAddress.Postcode), 1)
                                  ])
                                ]))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("span", _hoisted_23, [
                          _createElementVNode("strong", null, _toDisplayString(_selectedCustomer.value.ProposedPriceIncreaseNote), 1)
                        ])
                      ])
                    ])
                  ]),
                  _: 1
                })
              ]),
              content: _withCtx(() => [
                _createVNode(_component_q_table, {
                  style: {"height":"100%","width":"100%","position":"relative"},
                  columns: _columns.value,
                  rows: _unref(_customerStore).getCustomersView,
                  "rows-per-page-options": [20],
                  loading: _isLoading.value,
                  "loading-label": 'Getting Customers...',
                  pagination: _pagination.value,
                  "onUpdate:pagination": _cache[6] || (_cache[6] = ($event: any) => ((_pagination).value = $event)),
                  "row-key": "CustomerId",
                  "hide-pagination": "",
                  onRowClick: _cache[7] || (_cache[7] = (_, row) => GetCustomer(row)),
                  onRowDblclick: _cache[8] || (_cache[8] = 
										(_, row) =>
											Navigate('CustomerDetails', null, {
												customerId: row.CustomerId,
											})
									)
                }, {
                  "body-cell-id": _withCtx((props) => [
                    _createVNode(_component_q_td, {
                      props: props,
                      class: "q-pa-0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(GZIndicator), {
                          color: GetStatusIndicatorColour(props.row),
                          "indicator-wraper-style": 'height: 100%',
                          "indicator-style": 'height: 100%; position: absolute; top: 0; left: 0; width: 8px;'
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_tooltip, null, {
                              default: _withCtx(() => [
                                _createElementVNode("span", null, _toDisplayString(props.row.CustomerStatus), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["color"]),
                        _createElementVNode("b", null, _toDisplayString(props.row.AccountNumber), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"])
                  ]),
                  "body-cell-name": _withCtx((props) => [
                    _createVNode(TDMultiline, null, {
                      line1: _withCtx(() => [
                        _createElementVNode("b", null, _toDisplayString(props.row.CustomerName), 1)
                      ]),
                      line2: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.Group), 1)
                      ]),
                      line3: _withCtx(() => [
                        _createElementVNode("i", null, _toDisplayString(props.row.Town +
														(props.row.Town && props.row.Postcode ? ',' : '')) + _toDisplayString(props.row.Postcode), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  "body-cell": _withCtx((props) => [
                    _createVNode(_component_q_td, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.value), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_q_menu, {
                      "touch-position": "",
                      "context-menu": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_list, {
                          dense: "",
                          style: {"min-width":"100px"}
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_menuItems.value, (item) => {
                              return _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                key: item.Id,
                                disable: item.Disabled,
                                style: {"padding":"16px"},
                                clickable: "",
                                onClick: item.Callback
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_item_section, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.Name), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1032, ["disable", "onClick"])), [
                                [_directive_close_popup]
                              ])
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["columns", "rows", "loading", "pagination"]),
                _createVNode(_unref(GZPagination), {
                  class: "q-pt-sm q-px-sm",
                  "items-length": _unref(_customerStore).getCustomersView.length,
                  pagination: _pagination.value,
                  "onUpdate:pagination": _cache[9] || (_cache[9] = ($event: any) => ((_pagination).value = $event))
                }, null, 8, ["items-length", "pagination"])
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ])
      ])
    ]),
    _createVNode(_unref(GZDraggableModal), {
      ref_key: "createCustomerModal",
      ref: createCustomerModal,
      style: {"width":"600px"}
    }, {
      header: _withCtx(() => [
        _createVNode(_component_q_toolbar, { class: "bg-primary text-white" }, {
          default: _withCtx(() => [
            _createVNode(_component_q_toolbar_title, null, {
              default: _withCtx(() => _cache[18] || (_cache[18] = [
                _createTextVNode("Create Customer")
              ])),
              _: 1
            }),
            _createVNode(_component_q_space),
            _createVNode(_component_q_btn, {
              onClick: _cache[11] || (_cache[11] = ($event: any) => (_showCreateCustomer.value = false)),
              icon: "mdi-close",
              flat: ""
            })
          ]),
          _: 1
        })
      ]),
      body: _withCtx(() => [
        _createVNode(_component_q_card, {
          id: "audit-items",
          style: {"height":"calc(100% - var(--header-bar-height-small))","overflow-y":"auto"},
          class: "q-pa-md"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_form, {
              ref: "customerCreateForm",
              modelValue: _isCreateCustomerValid.value,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_isCreateCustomerValid).value = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_field, {
                  modelValue: _createCustomer.value.CustomerName,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_createCustomer.value.CustomerName) = $event)),
                  class: "pa-2",
                  style: {"align-self":"center"},
                  label: "Customer Name",
                  placeholder: "Enter Name...",
                  "persistent-placeholder": "",
                  outlined: "",
                  dense: "",
                  clearable: "",
                  "hide-details": "auto",
                  rules: [(v) => !!v || 'Name is required']
                }, null, 8, ["modelValue", "rules"]),
                _createVNode(_component_q_checkbox, {
                  class: "px-2",
                  modelValue: _createCustomer.value.ContractCustomer,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_createCustomer.value.ContractCustomer) = $event)),
                  label: "Extra?"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_q_field, {
                  modelValue: _createCustomer.value.AccountNumber,
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_createCustomer.value.AccountNumber) = $event)),
                  class: "pa-2",
                  style: {"align-self":"center"},
                  label: "Account Number",
                  placeholder: "Enter Number...",
                  "persistent-placeholder": "",
                  outlined: "",
                  dense: "",
                  clearable: "",
                  "hide-details": "auto",
                  rules: [(v) => !!v || 'Name is required']
                }, {
                  after: _withCtx(() => [
                    _createVNode(_component_q_btn, {
                      color: "warning",
                      variant: "text",
                      class: "font-weight-bold",
                      flat: ""
                    }, {
                      default: _withCtx(() => _cache[19] || (_cache[19] = [
                        _createTextVNode("Auto Generate")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue", "rules"])
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_24, [
          _createVNode(_component_q_btn, {
            disable: _isLoading.value || !_isCreateCustomerValid.value,
            color: "primary",
            rounded: "",
            flat: "",
            onClick: _cache[16] || (_cache[16] = ($event: any) => (CreateCustomer()))
          }, {
            default: _withCtx(() => _cache[20] || (_cache[20] = [
              _createTextVNode(" Create ")
            ])),
            _: 1
          }, 8, ["disable"])
        ])
      ]),
      _: 1
    }, 512)
  ]))
}
}

})