import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "internal-container" }

import { LoadingData, NoData } from '@gz/styleguide';
import { isLoading$ } from '@gz/api';
import { BaseService } from '@/services/BaseService';
import {
	Email,
	ICustomer,
	ISite,
	MenuItem,
	SlotItem,
} from 'greenzonegateway.classes/lib/classes';
import moment from 'moment';
import {
	CreateSiteDTO,
	IReadSiteDTO,
	ReadSiteDTO,
} from 'greenzonegateway.classes/lib/classes/customer/crud';
import { EmailTypeEnum } from 'greenzonegateway.classes/lib/classes/Generic/EmailType';
import { EmailService } from '@/services/EmailService';
import { CreateContact } from 'greenzonegateway.classes/lib/classes/customer/Contact';
import {
	computed,
	getCurrentInstance,
	nextTick,
	onBeforeMount,
	ref,
	watch,
} from 'vue';
import { useRouter } from 'vue-router';
import debounce from 'quasar/src/utils/debounce/debounce.js';;


export default /*@__PURE__*/_defineComponent({
  __name: 'ViewSites',
  props: { customerId: String, siteId: String },
  setup(__props) {

const _props = __props;

const _emailService = new EmailService();
const _service = new BaseService();
const _router = useRouter();

const _delayedFilterSites = debounce(FilterSites, 500);

const _emailTypeEnum = EmailTypeEnum;

const instance = getCurrentInstance();
const createContactForm = ref();
const changeSiteForm = ref();

const _search = ref('');
const _isLoading = ref(false);
const _loadingSubscription = ref();
const _fab = ref(false);
const _isSitesLoading = ref(false);
const _sites = ref<IReadSiteDTO[]>([]);
const _sitesBackup = ref<IReadSiteDTO[]>([]);
const _selectedSites = ref<IReadSiteDTO[]>([]);
const _isCustomersLoading = ref(false);
const _customers = ref<ICustomer[]>([]);
const _selectedCustomer = ref<ICustomer | null>(null);
const _addSiteCustomer = ref<ICustomer | null>(null);
const _shouldDisplayChangeSiteModal = ref(false);
const _shouldDisplayChangeSiteDialog = ref(false);
const _isChangeSitesLoading = ref(false);
const _shouldDisplayAddEditSiteModal = ref(false);
const _addEditSiteTitle = ref('');
const _isAddSite = ref(false);
const _tab = ref(null);
const _newSite = ref(new ReadSiteDTO());
const _updateSite = ref<any>({});
const _isAddSiteLoading = ref(false);
const _showEmailView = ref(false);
const _eire = ref(false);
const _email = ref(new Email());
const _shouldDisplaySiteContactsModal = ref(false);
const _contacts = ref<any[]>([]);
const _isContactsLoading = ref(false);
const _selectedContact: any = ref({});
const _contact = ref(new CreateContact());
const _shouldDisplayAddContactDialog = ref(false);
const _AddEditContactDialogTitle = ref('');
const _startDate = ref(moment().format('YYYY-MM-DD'));
const _headers = ref([
	{
		text: 'Name',
		value: 'SiteName',
		width: 'auto',
		groupable: false,
	},
	{
		text: 'Reference',
		value: 'SiteReference',
		width: 'auto',
		groupable: false,
	},
	{
		text: 'Address',
		value: 'Address.AddressLine1',
		width: 'auto',
		groupable: false,
	},
	{
		text: 'Contact',
		value: 'Address.Email',
		width: 'auto',
		slot: true,
		groupable: false,
	},
	{
		text: 'Live Services',
		value: 'LiveServices',
		width: 'auto',
		groupable: false,
	},
]);

const _contactsHeaders = ref([
	{
		text: 'Full Name',
		align: 'start',
		sortable: false,
		value: 'FullName',
	},
	{
		text: 'Address',
		align: 'start',
		sortable: false,
		value: 'AddressLine1',
		width: '30%',
	},
	{
		text: 'Contact',
		align: 'start',
		sortable: false,
		value: 'Email',
		width: 'auto',
	},
]);

const _slots = ref<SlotItem[]>([
	{
		Name: 'Address.AddressLine1',
		Line1: 'Address.AddressLine1',
		Line2: 'Address.County',
		Line3: 'Address.Town',
		Line4: 'Address.Postcode',
	},
	{
		Name: 'Address.Email',
		Line1: 'Address.Email',
		Line2: 'Address.Telephone',
		Line3: 'Address.Mobile',
		Line4: 'Address.Work',
	},
]);
const _menuItems = ref<MenuItem[]>([
	{
		Name: 'Email Supplier',
		Callback: OnEmailSupplierClicked,
		Disabled: false,
		ShowCount: false,
		Confirm: () => '',
	},
	{
		Name: 'Web Access',
		Callback: GoToWebAccess,
		Disabled: false,
		ShowCount: false,
		Confirm: () => '',
		Colour: 'var(--v-error-darken1) !important',
	},
	{
		Name: 'Edit Site',
		Callback: OnEditSiteClicked,
		Disabled: false,
		ShowCount: false,
		Confirm: () => '',
		Colour: 'var(--v-error-darken1) !important',
	},
	{
		Name: 'Change Customer',
		Callback: ShowChangeSiteModal,
		Disabled: false,
		ShowCount: false,
		Confirm: () => '',
		Colour: 'var(--v-error-darken1) !important',
	},
	{
		Name: 'Site Contacts',
		Callback: ShowSiteContactModal,
		Disabled: false,
		ShowCount: false,
		Confirm: () => '',
		Colour: 'var(--v-error-darken1) !important',
	},
]);

watch(_eire, (current: boolean) => {
	if (current) {
		_updateSite.value.Postcode = 'eire';
		_newSite.value.Address.Postcode = 'eire';
	}
});

watch(_selectedSites, (newValue: any) => {
	let items = _menuItems.value.filter(
		(xx) => xx.Name === 'Web Access' || 'Edit Site'
	);
	if (newValue.length > 1) {
		if (items) {
			items.forEach((item) => (item.Disabled = true));
		}
	} else {
		if (items) {
			items.forEach((item) => (item.Disabled = false));
		}
	}
});

const _outgoingEmailCompute = computed(() => {
	let outgoing: string[] = [];
	if (_selectedSites.value[0].Address?.Email) {
		outgoing.push(..._selectedSites.value[0].Address.Email?.split(/[;,]/));
	}
	if (!_selectedSites.value[0].Customer?.CustomerAddress?.Email) {
		outgoing.push(
			..._selectedSites.value[0].Customer.CustomerAddress.Email?.split(/[;,]/)
		);
	}
	return outgoing;
});

onBeforeMount(async () => {
	_loadingSubscription.value = isLoading$.subscribe((isLoading: boolean) => {
		_isLoading.value = isLoading;
	});
	if (_props.customerId) {
		await GetSites();
		await GetCustomers();
	}
});

function ShowChangeSiteModal() {
	_shouldDisplayChangeSiteModal.value = true;
	_selectedCustomer.value = null;
	Focus();
}

async function GetSites() {
	_isSitesLoading.value = true;
	_sites.value = [];
	Focus();

	await _service
		.Get<IReadSiteDTO[]>(`Sites/Customer/${_props.customerId}`)
		.then((sites: IReadSiteDTO[]) => {
			_sites.value = sites;
			_sitesBackup.value = sites;
			_isSitesLoading.value = false;

			if (_props.siteId) {
				_newSite.value =
					sites.find((xx) => Number(xx.SiteId) === Number(_props.siteId)) ??
					new ReadSiteDTO();
				_shouldDisplayAddEditSiteModal.value = true;
			}
		});
}
function OnEmailSupplierClicked() {
	_showEmailView.value = true;
}

function OnAddContactClicked() {
	_shouldDisplayAddContactDialog.value = true;
	_AddEditContactDialogTitle.value = 'Add Contact';
}
function OnNewContactCreated(contact: any) {
	_shouldDisplayAddContactDialog.value = false;
	contact = new CreateContact();
	_contacts.value.push(contact);
	(createContactForm as any).resetValidation();
}
async function ShowSiteContactModal() {
	_shouldDisplaySiteContactsModal.value = true;
	_shouldDisplayAddContactDialog.value = false;
	GetContactsForSite();
}

async function GetContactsForSite() {
	_isContactsLoading.value = true;
	await _service
		.Get<any[]>(
			`Contacts?customerId=${_props.customerId}&siteId=${_selectedSites.value[0].SiteId}`
		)
		.then((contacts: any[]) => {
			_contacts.value = contacts;
			_isContactsLoading.value = false;
		});
}

function FilterSites(search: string) {
	if (!search) {
		_sites.value = _sitesBackup.value;
	}
	_sites.value = _sitesBackup.value.filter(
		(site) =>
			site.SiteId?.toString().includes(search) ||
			site.CustomerId?.toString().includes(search) ||
			site.SiteReference?.toString().includes(search) ||
			site.SiteName?.toLowerCase().includes(search.toLowerCase()) ||
			site.SiteStatus?.toLowerCase().includes(search.toLowerCase()) ||
			site.Address?.AddressLine1?.toLowerCase().includes(
				search.toLowerCase()
			) ||
			site.Address?.AddressLine2?.toLowerCase().includes(
				search.toLowerCase()
			) ||
			site.Address?.Town?.toLowerCase().includes(search.toLowerCase()) ||
			site.Address?.Email?.toLowerCase().includes(search.toLowerCase()) ||
			site.Address?.Postcode?.toLowerCase().includes(search.toLowerCase()) ||
			site.Address?.Telephone?.includes(search) ||
			site.LiveServices?.toString().includes(search)
	);
}

function ResetEmail() {
	_showEmailView.value = false;
	_email.value = new Email();
}

function ValidateChangeSite() {
	if ((changeSiteForm as any).validate()) {
		_shouldDisplayChangeSiteDialog.value = true;
	}
}

function OnAddSiteClicked() {
	_updateSite.value = {};
	_newSite.value = new ReadSiteDTO();
	_isAddSite.value = true;
	_shouldDisplayAddEditSiteModal.value = true;
}

function OnEditSiteClicked() {
	_isAddSite.value = false;
	_newSite.value = JSON.parse(JSON.stringify(_selectedSites.value[0]));
	_updateSite.value = {};
	_shouldDisplayAddEditSiteModal.value = true;
}
function ShowContactEditDialog(contact: any) {
	_shouldDisplayAddContactDialog.value = true;
	_AddEditContactDialogTitle.value = 'Edit Contact';
	_contact.value = contact;
}

function ChangeSiteCustomer() {
	if (!_isChangeSitesLoading.value) {
		let request = {
			customerId: Number(_selectedCustomer.value?.CustomerId),
			siteIds: _selectedSites.value.map((site: IReadSiteDTO) => site.SiteId),
			FromDate: _startDate.value,
		};
		_isChangeSitesLoading.value = true;
		_shouldDisplayChangeSiteDialog.value = false;
		_service
			.Post<any>('Sites/Update/Customer', request)
			.then(() => {
				_selectedSites.value.forEach((site: IReadSiteDTO) => {
					const index = _sites.value.indexOf(site);
					_sites.value.splice(index, 1);
					_selectedSites.value = [];
					_selectedCustomer.value = null;
				});
			})
			.finally(() => {
				_shouldDisplayChangeSiteModal.value = false;
				_isChangeSitesLoading.value = false;
			});
	}
}

function Focus() {
	nextTick(() => {
		instance?.proxy?.$forceUpdate();
	});
}
function GoToWebAccess() {
	_router.push({
		name: 'WebAccess',
		query: {
			customerId: _props.customerId,
			siteId: _selectedSites.value[0].SiteId?.toString(),
		},
	});
}
async function GetCustomers() {
	_isCustomersLoading.value = true;
	_service
		.Get<ICustomer[]>('Customers/List')
		.then((customers) => {
			_customers.value = customers;
			_addSiteCustomer.value = customers.filter(
				(x) => x.CustomerId == _props.customerId
			)[0];
		})
		.finally(() => {
			_isCustomersLoading.value = false;
		});
}
function CancelCreateContact() {
	_shouldDisplayAddContactDialog.value = false;
	_contact.value = new CreateContact();
	(createContactForm as any).resetValidation();
}
function OnSiteUpdated(site: IReadSiteDTO) {
	if (_isAddSite.value) {
		_sites.value.unshift(site);
	} else {
		_sites.value = _sites.value.map((s) =>
			s.SiteId !== site.SiteId ? s : site
		);
	}
}

return (_ctx: any,_cache: any) => {
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_toolbar, { class: "bg-accent text-white q-pl-none" }, {
      default: _withCtx(() => [
        _createVNode(_component_q_toolbar_title, { style: {"padding-left":"16px"} }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Sites")
          ])),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})