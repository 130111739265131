import { EmailTypeEnum } from 'greenzonegateway.classes/lib/classes';
import { BaseService } from './BaseService';
import { IEmail } from 'greenzonegateway.classes/lib/classes/tickets/interfaces/IEmail';

export class EmailService {
	service = new BaseService();

	public GetEmail(
		noteId: number | undefined,
		emailType: EmailTypeEnum
	): Promise<IEmail> {
		return this.service.Get<IEmail>(`Notes/Email/${noteId}/Type/${emailType}`);
	}

	BuildHtml(html: string, returnText = false) {
		if (!html) {
			return '';
		}

		// make a new parser
		const parser = new DOMParser();

		// convert html string into DOM
		const document2 = parser.parseFromString(html, 'text/html');

		setTimeout(() => {
			document.getElementsByTagName('aside')[1].style.width = '650px';
		}, 0);

		const potentialInternalMessages = document2.querySelectorAll(
			'.MsoNormal > span'
		).length
			? document2.querySelectorAll('.MsoNormal > span')
			: document2.querySelectorAll('span');

		for (let index = 0; index < potentialInternalMessages.length; index++) {
			const element = potentialInternalMessages[index];

			if (
				element.innerHTML.includes('CAUTION: This email') ||
				element.innerHTML.includes(
					'The information in this email (which includes any files transmitted with it) is confidential'
				) ||
				element.innerHTML.includes(
					'All emails sent by Greenzone Facilities Management Limited'
				) ||
				element.innerHTML.includes(
					'All work carried out by or for Greenzone is subject to Greenzone'
				) ||
				element.innerHTML.includes('registered office is The Corner House') ||
				element.innerHTML.includes(
					'Please consider your environmental responsibility, before printing this email ask'
				)
			) {
				const parent = element.parentElement;
				element.remove();
				parent?.remove();
			}
		}

		// Apply styles
		['table', 'div:first-of-type', 'span:first-of-type'].forEach((selector) => {
			const elements = document2.querySelectorAll(selector) as any;

			for (let index = 0; index < elements.length; index++) {
				const element = elements[index];
				if (element) {
					element.style.width = '100%';
					element.style.wordBreak = 'break-word';
					element.style.background = 'none';
					element.style.backgroundColor = 'transparent';
				}
			}
		});

		// Remove unwanted elements
		['font > style', 'script', 'img'].forEach((selector) => {
			const elements = document2.querySelectorAll(selector);
			elements.forEach((element) => {
				element.remove();
			});
		});

		if (returnText) {
			return document2.querySelectorAll('*')[0].textContent;
		} else {
			return document2.activeElement?.innerHTML || html;
		}
	}
}
