import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "internal-container" }

import { useCustomerStore } from '@/store/customer';
import { LoadingData, NoData } from '@gz/styleguide';
import { isLoading$ } from '@gz/api';

import { IReadServiceItem } from 'greenzonegateway.classes/lib/classes/service/serviceItems/interfaces/IReadServiceItem';
import { BaseService } from '@/services/BaseService';
import { ICustomer, ISite } from 'greenzonegateway.classes/lib/classes';
import {
	IInvoice,
	Invoice,
} from 'greenzonegateway.classes/lib/classes/invoice/Invoice';
import { IReadTicket } from 'greenzonegateway.classes/lib/classes/tickets/interfaces/IReadTicket';
import { onBeforeMount, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomerDetails',
  props: { customerId: { type: String } },
  setup(__props) {

const _route = useRoute();
const _router = useRouter();
const customerStore = useCustomerStore();

const _props = __props;

const _loadingSubscription = ref(false);
const _showFilterMenu = ref(false);
const _fab = ref<any>(null);

const _service = new BaseService();
const _isCustomersFormValid = ref(false);
const _selectedCustomer = ref({});
const _topServiceItems = ref<IReadServiceItem[]>([]);

const _recentSites = ref<ISite[]>([]);
const _recentInvoices = ref<IInvoice[]>([]);
const _recentTickets = ref<IReadTicket[]>([]);
const _recentDocuments = ref([]);
const _complianceEnums = ref([21, 20]);

const _isLoading = ref(false);
const _isCombinedDocumentsLoading = ref(false);
const _isRecentSitesLoading = ref(false);
const _isRecentInvoicesLoading = ref(false);
const _isRecentServiceItemsLoading = ref(false);
const _isRecentTicketsLoading = ref(false);
const _isVitalsLoading = ref(false);
const _isIssuesLoading = ref(false);

watch(
	customerStore.getCustomers,
	(current: ICustomer[], previous: ICustomer[]) => {
		if (current.length) {
			_selectedCustomer.value = customerStore.getCustomers.filter(
				(x: any) => Number(x.CustomerId) === Number(_props.customerId)
			)[0];
		}
	}
);

onBeforeMount(() => {
	_loadingSubscription.value = isLoading$.subscribe((isLoading: boolean) => {
		_isLoading.value = isLoading;
	});

	if (_route.params.customerId) {
		GetData();
	}
});

function GetData() {
	_showFilterMenu.value = false;
	if (_props.customerId) {
		_router.replace({
			params: {
				customerId: _props.customerId?.toString(),
			},
		});
	}

	GetServiceItems();
	GetSites();
	GetInvoices();
	GetTickets();
	GetCombinedDocuments();
}

function GetServiceItems(): void {
	_topServiceItems.value = [];
	_isRecentServiceItemsLoading.value = true;
	_service
		.Get<IReadServiceItem[]>(
			`ServiceItems/${_props.customerId}?take=5&includeExtras=true`
		)
		.then((serviceItems: IReadServiceItem[]) => {
			_topServiceItems.value = serviceItems;
		})
		.finally(() => {
			_isRecentServiceItemsLoading.value = false;
		});
}

function GetSites(): void {
	_recentSites.value = [];
	_isRecentSitesLoading.value = true;
	_service
		.Get<ISite[]>(`Sites/Customer/${_props.customerId}?take=5`)
		.then((sites: ISite[]) => {
			_recentSites.value = sites;
		})
		.finally(() => {
			_isRecentSitesLoading.value = false;
		});
}

function GetInvoices(): void {
	_recentInvoices.value = [];
	_isRecentInvoicesLoading.value = true;
	_service
		.Get<IInvoice[]>(`Invoices/Customer/${_props.customerId}?take=5`)
		.then((invoices: IInvoice[]) => {
			_recentInvoices.value = invoices;
		})
		.finally(() => {
			_isRecentInvoicesLoading.value = false;
		});
}

function GetTickets(): void {
	_recentTickets.value = [];
	_isRecentTicketsLoading.value = true;
	_service
		.Get<IReadTicket[]>(`Tickets/Customer/${_props.customerId}?take=5`)
		.then((tickets: IReadTicket[]) => {
			_recentTickets.value = tickets;
		})
		.finally(() => {
			_isRecentTicketsLoading.value = false;
		});
}

function GetCombinedDocuments(): void {
	_recentDocuments.value = [];
	_isCombinedDocumentsLoading.value = true;
	_service
		.Get<any[]>(`Documents/Combined/${_props.customerId}?take=5`)
		.then((documents: any[]) => {
			_recentDocuments.value = documents;
		})
		.finally(() => {
			_isCombinedDocumentsLoading.value = false;
		});
}

function Navigate(
	name: string,
	query: any = null,
	params: any = null,
	isExternal = false
): void {
	if (!isExternal) {
		_router.push({
			name: name,
			query: query,
			params: params,
		});
	} else {
		_router.push({ path: name, query: query });
	}
}
function OnDocumentClicked(doc: any) {
	if (doc.FileLocation) {
		let url;
		if (_complianceEnums.value.includes(doc.DocumentTypeId)) {
			url = `Documents/Download/Compliance/${doc.DocumentId}`;
		} else {
			url = `Documents/Download/${doc.DocumentId}`;
		}

		_service.Get<any>(url).then((res: string) => {
			window.open(res, '_blank');
		});
	} else {
		_service
			.GetAxios(`Documents/Download/${doc.DocumentId}`, '', {
				responseType: 'blob',
			})
			.then((response: any) => {
				const fileType = doc.Extension;
				const name = doc.Filename.split('.')[0];
				const href = URL.createObjectURL(response.data);
				const link = document.createElement('a');

				link.href = href;
				link.setAttribute('download', name + '.' + fileType);
				document.body.appendChild(link);
				link.click();
			});
	}
}
function GoToWTNs() {
	_router
		.push(`customers/wasteTransferNotes/${_props.customerId}`)
		.catch((err) => console.log(err));
}

return (_ctx: any,_cache: any) => {
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_toolbar, { class: "bg-accent text-white q-pl-none" }, {
      default: _withCtx(() => [
        _createVNode(_component_q_toolbar_title, { style: {"padding-left":"16px"} }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Customer Details")
          ])),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})