<template>
	<div class="internal-container">
		<q-toolbar class="bg-accent text-white q-pl-none">
			<q-toolbar-title style="padding-left: 16px"
				>Customer Details</q-toolbar-title
			>
		</q-toolbar>
		<!-- <v-app-bar color="primary darken-2" dark>
			<v-toolbar-title>Details</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-toolbar-title>{{
				$route.params.customerId
					? customerStore.getCustomerFromId($route.params.customerId)?.CustomerName
					: ''
			}}</v-toolbar-title>
			<v-spacer></v-spacer>
			<div style="display: flex" class="mr-n3">
				<v-menu
					v-model="_showFilterMenu"
					:close-on-content-click="false"
					:nudge-width="200"
					offset-y
					max-width="25%"
					min-width="300px"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon v-bind="attrs" v-on="on">
							<v-icon>mdi-filter-variant</v-icon>
						</v-btn>
					</template>

					<v-card>
						<v-toolbar flat dense>
							<v-toolbar-title>Filters</v-toolbar-title>
							<v-spacer></v-spacer>
						</v-toolbar>

						<v-form
							style="overflow-y: auto; height: 100%"
							v-model="_isCustomersFormValid"
							ref="customersForm"
						>
							<v-container class="pt-0">
								<v-row class="py-0">
									<v-col class="px-3 py-2">
										<GenericSelect
											class="pt-4"
											id="Select-Customer"
											v-model:searchItems="customerStore.getCustomers"
											v-model:selectedId="customerId"
											v-model="_selectedCustomer"
											:type="'Customer'"
											:listName="'CustomerName'"
											:listSubName="'AccountNumber'"
											:listValue="'CustomerId'"
											:loading="false"
											@change-item="GetData"
										>
										</GenericSelect>
									</v-col>
								</v-row>
							</v-container>
						</v-form>

						<v-divider></v-divider>

						<v-card-actions style="flex-direction: row-reverse">
							<v-btn variant="text"> Close </v-btn>
						</v-card-actions>
					</v-card>
				</v-menu>
			</div>
		</v-app-bar>

		<div class="pa-4 pb-0 layout-container" fluid>
			<v-row style="height: 100%" no-gutters>
				<v-col
					cols="4"
					sm="12"
					md="4"
					lg="4"
					xl="4"
					class="pb-0 px-2"
					style="position: relative; height: 100%"
				>
					<v-row no-gutters style="height: 100%">
						<v-col cols="12" class="pb-0" style="position: relative; height: 50%">
							<div style="height: 100%">
								<div class="mx-auto" style="height: 100%">
									<v-toolbar dark dense rounded style="z-index: 100" color="primary darken-2">
										<v-toolbar-title>Recent Services </v-toolbar-title>
										<v-spacer></v-spacer>
										<v-btn
											outlined
											rounded
											small
											class="ml-4"
											color="primary-darken2"
											@click="
												Navigate('ServiceItems', null, {
													customerId: customerId,
												})
											"
											>view <v-icon class="pl-0">mdi-arrow-right</v-icon>
										</v-btn>
									</v-toolbar>
									<v-card
										elevation="4"
										class="pa-2 overflow-y-auto"
										style="border-radius: 0; height: calc(100% - var(--default-padding) * 4)"
									>
										<v-list
											class="pl-2"
											v-if="!_isRecentServiceItemsLoading && _topServiceItems.length"
										>
											<ServiceItemCard
												v-for="serviceItem in _topServiceItems"
												:key="serviceItem.ServiceItemId"
												:serviceItem="serviceItem"
												@click="
													Navigate(`ServiceItems`, null, {
														serviceItemId: serviceItem.ServiceItemId,
													})
												"
											></ServiceItemCard>
										</v-list>
										<LoadingData
											v-if="_isRecentServiceItemsLoading"
											:loadingDataText="'Getting Service Details...'"
										>
										</LoadingData>
										<NoData
											v-if="!_isRecentServiceItemsLoading && !_topServiceItems.length"
											:label="'No Recent Services'"
											:icon="'mdi-ticket-percent'"
										>
										</NoData>
									</v-card>
								</div>
							</div>
						</v-col>

						<v-col cols="12" class="pb-0" style="position: relative; height: 50%">
							<div style="height: 100%">
								<div class="mx-auto" style="height: 100%">
									<v-toolbar dark dense rounded style="z-index: 100" color="primary darken-2">
										<v-toolbar-title>Recent Sites </v-toolbar-title>
										<v-spacer></v-spacer>
										<v-btn
											outlined
											rounded
											small
											class="ml-4"
											color="primary-darken2"
											@click="Navigate(`Sites`, null, { customerId: customerId })"
											>view <v-icon class="pl-0">mdi-arrow-right</v-icon>
										</v-btn>
									</v-toolbar>
									<v-card
										elevation="4"
										class="pa-2 overflow-y-auto"
										style="border-radius: 0; height: calc(100% - var(--default-padding) * 4)"
									>
										<v-list v-if="!_isRecentSitesLoading && _recentSites.length">
											<v-list-item active-class="pa-0" class="pa-0">
												<v-list-item-content class="pa-0" style="width: 100%">
													<v-card
														v-for="site in _recentSites"
														:key="site.SiteId"
														@click="
															Navigate(`Sites`, null, {
																customerId: site.CustomerId,
																siteId: site.SiteId,
															})
														"
														v-ripple
														flat
														class="py-2"
													>
														<v-row style="width: 100%">
															<v-col cols="7">
																<v-list-item-title>
																	{{ site.SiteName }}
																</v-list-item-title>
																<v-list-item-subtitle>
																	{{ site.SiteReference }}
																</v-list-item-subtitle>
																<v-list-item-subtitle
																	:style="{
																		color: site.LiveServices
																			? 'var(--v-green-base)'
																			: 'var(--v-error-base)',
																	}"
																>
																	{{
																		site.LiveServices > 1
																			? site.LiveServices + ' Services'
																			: !site.LiveServices
																			? 'No Services'
																			: site.LiveServices + ' Service'
																	}}
																</v-list-item-subtitle>
															</v-col>

															<v-col style="text-align: right" cols="5">
																<v-list-item-subtitle>
																	{{ site.Address?.AddressLine1 }}
																</v-list-item-subtitle>
																<v-list-item-subtitle>
																	<span>
																		{{ site.Address?.Postcode }}
																	</span>
																</v-list-item-subtitle>
															</v-col>
														</v-row>
													</v-card>
												</v-list-item-content>
											</v-list-item>
										</v-list>
										<LoadingData
											v-if="_isRecentSitesLoading"
											:loadingDataText="'Getting Recent Sites...'"
										>
										</LoadingData>
										<NoData
											v-if="!_isRecentSitesLoading && !_recentSites.length"
											:label="'No Recent Sites'"
											:icon="'mdi-ticket-percent'"
										>
										</NoData>
									</v-card>
								</div>
							</div>
						</v-col>
					</v-row>
				</v-col>

				<v-col
					cols="4"
					sm="12"
					md="4"
					lg="4"
					xl="4"
					class="pb-0 px-2"
					style="position: relative; height: 100%"
				>
					<v-row no-gutters style="height: 100%">
						<v-col cols="12" class="pb-0" style="position: relative; height: 30%">
							<v-row style="height: 100%">
								<v-col cols="6" style="height: 100%" class="pb-0">
									<div style="height: 100%">
										<div class="mx-auto" style="height: 100%">
											<v-toolbar dark dense rounded style="z-index: 100" color="primary darken-2">
												<v-toolbar-title>Recent Invoices </v-toolbar-title>
												<v-spacer></v-spacer>
												<v-btn
													outlined
													rounded
													small
													class="ml-4"
													color="primary-darken2"
													@click="Navigate(`Invoices`)"
													>View <v-icon class="pl-0">mdi-arrow-right</v-icon>
												</v-btn>
											</v-toolbar>
											<v-card
												elevation="4"
												class="pa-2 overflow-y-auto"
												style="
													border-radius: 0;
													height: calc(100% - var(--header-bar-height-small));
												"
											>
												<v-list v-if="!_isRecentInvoicesLoading && _recentInvoices.length">
													<v-list-item active-class="pa-0" class="pa-0">
														<v-list-item-content class="pa-0" style="width: 100%">
															<v-card
																v-for="invoice in _recentInvoices"
																:key="invoice.InvoiceNo"
																@click="
																	Navigate(`Invoices`, null, {
																		siteId: invoice.InvoiceNo,
																	})
																"
																v-ripple
																flat
																class="py-2"
															>
																<v-row style="width: 100%">
																	<v-col cols="7">
																		<v-list-item-title>
																			{{ '£' + (invoice.TotalIncVAT ?? 0) }}
																		</v-list-item-title>
																		<v-list-item-subtitle>
																			{{ invoice.InvoiceNo }}
																		</v-list-item-subtitle>
																	</v-col>

																	<v-col style="text-align: right" cols="5">
																		<v-list-item-subtitle>
																			{{ invoice.InvoiceDate.Display }}
																		</v-list-item-subtitle>
																		<v-list-item-subtitle
																			:style="{
																				color:
																					Number(invoice.Allocated) === Number(invoice.TotalIncVAT)
																						? 'var(--v-green-base)'
																						: 'var(--v-error-base)',
																			}"
																		>
																			<span>
																				{{
																					Number(invoice.Allocated) === Number(invoice.TotalIncVAT)
																						? 'Paid'
																						: 'Unpaid'
																				}}
																			</span>
																		</v-list-item-subtitle>
																	</v-col>
																</v-row>
															</v-card>
														</v-list-item-content>
													</v-list-item>
												</v-list>
												<LoadingData
													v-if="_isRecentInvoicesLoading"
													:loadingDataText="'Getting Invoice Details...'"
												>
												</LoadingData>
												<NoData
													v-if="!_isRecentInvoicesLoading && !_recentInvoices.length"
													:label="'No Recent Invoices'"
													:icon="'mdi-ticket-percent'"
												>
												</NoData>
											</v-card>
										</div>
									</div>
								</v-col>

								<v-col cols="6" style="height: 100%" class="pb-0">
									<div style="height: 100%">
										<div class="mx-auto" style="height: 100%">
											<v-toolbar dark dense rounded style="z-index: 100" color="primary darken-2">
												<v-toolbar-title>Issues </v-toolbar-title>
												<v-spacer></v-spacer>
											</v-toolbar>
											<v-card
												elevation="4"
												class="pa-2 overflow-y-auto"
												style="
													border-radius: 0;
													height: calc(100% - var(--header-bar-height-small));
												"
											>
												<LoadingData v-if="_isIssuesLoading" :loadingDataText="'Getting Issues...'">
												</LoadingData>
												<NoData
													v-if="!_isIssuesLoading"
													:label="'Not Implemented'"
													:icon="'mdi-ticket-percent'"
												>
												</NoData>
											</v-card>
										</div>
									</div>
								</v-col>
							</v-row>
						</v-col>

						<v-col cols="12" class="pb-0" style="position: relative; height: 35%">
							<div style="height: 100%">
								<div class="mx-auto" style="height: 100%">
									<v-toolbar dark dense rounded style="z-index: 100" color="primary darken-2">
										<v-toolbar-title>Open Tickets </v-toolbar-title>
										<v-spacer></v-spacer>
										<v-btn
											outlined
											rounded
											small
											class="ml-4"
											color="primary-darken2"
											@click="Navigate(`/customersupport/tickets`, null, null, true)"
											>view<v-icon class="pl-0">mdi-arrow-right</v-icon>
										</v-btn>
									</v-toolbar>
									<v-card
										elevation="4"
										class="pa-2 overflow-y-auto"
										style="border-radius: 0; height: calc(100% - var(--default-padding) * 4)"
									>
										<v-list v-if="!_isRecentTicketsLoading && _recentTickets.length">
											<v-list-item active-class="pa-0" class="pa-0">
												<v-list-item-content class="pa-0" style="width: 100%">
													<v-card
														v-for="ticket in _recentTickets"
														:key="ticket.TicketId"
														@click="
															Navigate(
																`/customersupport/tickets/edit`,
																{
																	ticketId: ticket.TicketId,
																},
																null,
																true
															)
														"
														v-ripple
														flat
														class="py-2"
													>
														<v-row style="width: 100%">
															<v-col cols="7">
																<v-list-item-title>
																	{{ '#' + ticket.TicketId + '#' }}
																</v-list-item-title>
																<v-list-item-subtitle>
																	{{ ticket.Description }}
																</v-list-item-subtitle>
																<v-list-item-subtitle>
																	{{ ticket.Site?.SiteName }}
																</v-list-item-subtitle>
															</v-col>

															<v-col style="text-align: right" cols="5">
																<v-list-item-subtitle>
																	{{ ticket.LastUpdated.Display }}
																</v-list-item-subtitle>
																<v-list-item-subtitle>
																	<span>
																		{{ ticket.CreatedBy }}
																	</span>
																</v-list-item-subtitle>
															</v-col>
														</v-row>
													</v-card>
												</v-list-item-content>
											</v-list-item>
										</v-list>
										<LoadingData
											v-if="_isRecentTicketsLoading"
											:loadingDataText="'Getting Ticket Details...'"
										>
										</LoadingData>
										<NoData
											v-if="!_isRecentTicketsLoading && !_recentTickets.length"
											:label="'No Recent Tickets'"
											:icon="'mdi-ticket-percent'"
										>
										</NoData>
									</v-card>
								</div>
							</div>
						</v-col>

						<v-col cols="12" class="pb-0" style="position: relative; height: 35%">
							<div style="height: 100%">
								<div class="mx-auto" style="height: 100%">
									<v-toolbar dark dense rounded style="z-index: 100" color="primary darken-2">
										<v-toolbar-title>Recent Documents </v-toolbar-title>
										<v-spacer></v-spacer>
										<v-btn
											outlined
											rounded
											small
											class="ml-4"
											color="primary-darken2"
											@click="
												Navigate(`Documents`, null, {
													customerId: customerId,
												})
											"
											>View <v-icon class="pl-0">mdi-arrow-right</v-icon>
										</v-btn>
									</v-toolbar>
									<v-card
										elevation="4"
										class="pa-2 overflow-y-auto"
										style="border-radius: 0; height: calc(100% - var(--default-padding) * 4)"
									>
										<v-list v-if="!_isCombinedDocumentsLoading && _recentDocuments.length">
											<v-list-item active-class="pa-0" class="pa-0">
												<v-list-item-content class="pa-0" style="width: 100%">
													<v-card
														v-for="document in _recentDocuments"
														:key="document.DocumentId"
														@click="OnDocumentClicked(document)"
														v-ripple
														flat
														class="py-2"
													>
														<v-row style="width: 100%">
															<v-col cols="7">
																<v-list-item-title>
																	{{ document.DocumentType.Name }}
																</v-list-item-title>
																<v-list-item-subtitle>
																	{{ document.Filename }}
																</v-list-item-subtitle>
															</v-col>

															<v-col style="text-align: right" cols="5">
																<v-list-item-subtitle>
																	{{ document.CreatedDate.Display }}
																</v-list-item-subtitle>
															</v-col>
														</v-row>
													</v-card>
												</v-list-item-content>
											</v-list-item>
										</v-list>
										<LoadingData
											v-if="_isCombinedDocumentsLoading"
											:loadingDataText="'Getting Recent Document...'"
										>
										</LoadingData>
										<NoData
											v-if="!_isCombinedDocumentsLoading && !_recentDocuments.length"
											:label="'No Recent Documents'"
											:icon="'mdi-ticket-percent'"
										>
										</NoData>
									</v-card>
								</div>
							</div>
						</v-col>
					</v-row>
				</v-col>

				<v-col
					cols="4"
					sm="12"
					md="4"
					lg="4"
					xl="4"
					class="pb-0 px-2"
					style="position: relative; height: 100%"
				>
					<div style="height: 100%">
						<div class="mx-auto" style="height: 100%">
							<v-toolbar dark dense rounded style="z-index: 100" color="primary darken-2">
								<v-toolbar-title>Vitals </v-toolbar-title>
								<v-spacer></v-spacer>
								<v-btn
									outlined
									rounded
									small
									class="ml-4"
									color="primary-darken2"
									@click="
										Navigate(`Edit Customer Details`, null, {
											customerId: customerId,
										})
									"
									>View <v-icon class="pl-0">mdi-arrow-right</v-icon>
								</v-btn>
							</v-toolbar>
							<v-card
								elevation="4"
								class="pa-2 overflow-y-auto"
								style="border-radius: 0; height: calc(100% - var(--default-padding) * 4)"
							>
								<LoadingData v-if="_isVitalsLoading" :loadingDataText="'Getting Vitals...'">
								</LoadingData>
								<NoData
									v-if="!_isVitalsLoading"
									:label="'Not Implemented'"
									:icon="'mdi-ticket-percent'"
								>
								</NoData>
							</v-card>
						</div>
					</div>
				</v-col>
			</v-row>
		</div>

		<v-speed-dial
			fixed
			v-model="_fab"
			bottom
			right
			direction="top"
			:open-on-hover="true"
			:transition="'slide-y-reverse-transition'"
		>
			<template v-slot:activator>
				<v-btn v-model="_fab" color="primary darken-2" dark fab>
					<v-icon v-if="_fab"> mdi-close </v-icon>
					<v-icon v-else> mdi-dots-vertical </v-icon>
				</v-btn>
			</template>
			<v-tooltip value="true" left color="secondary" nudge-top="13" z-index="20">
				<template v-slot:activator="{}">
					<v-btn
						@click="Navigate('WebAccessCustomer', null, { customerId: customerId })"
						color="secondary"
						fab
						dark
						small
					>
						<v-icon>mdi-key-link</v-icon>
					</v-btn>
				</template>
				<span>Web Access</span>
			</v-tooltip>
			<v-tooltip value="true" left color="secondary" nudge-top="13" z-index="20">
				<template v-slot:activator="{}">
					<v-btn
						@click="
							Navigate('WasteTransferNotesCustomer', null, {
								customerId: customerId,
							})
						"
						color="secondary"
						fab
						dark
						small
					>
						<v-icon>mdi-file-document-outline</v-icon>
					</v-btn>
				</template>
				<span>WTN Management</span>
			</v-tooltip>

			<v-tooltip value="true" left color="secondary" nudge-top="13" z-index="20">
				<template v-slot:activator="{}">
					<v-btn @click="Navigate('OverweightDefaults')" color="secondary" fab dark small>
						<v-icon>mdi-weight</v-icon>
					</v-btn>
				</template>
				<span>Overweight Defaults</span>
			</v-tooltip>
			<v-tooltip value="true" left color="secondary" nudge-top="13" z-index="20">
				<template v-slot:activator="{}">
					<v-btn
						@click="
							Navigate(`Documents`, null, {
								customerId: customerId,
							})
						"
						color="secondary"
						fab
						dark
						small
					>
						<v-icon>mdi-file-document-edit</v-icon>
					</v-btn>
				</template>
				<span>Documents</span>
			</v-tooltip>
		</v-speed-dial> -->
	</div>
</template>

<style lang="scss" scoped>
@import 'CustomerDetails.scss';
</style>

<script setup lang="ts">
import { useCustomerStore } from '@/store/customer';
import { LoadingData, NoData } from '@gz/styleguide';
import { isLoading$ } from '@gz/api';

import { IReadServiceItem } from 'greenzonegateway.classes/lib/classes/service/serviceItems/interfaces/IReadServiceItem';
import { BaseService } from '@/services/BaseService';
import { ICustomer, ISite } from 'greenzonegateway.classes/lib/classes';
import {
	IInvoice,
	Invoice,
} from 'greenzonegateway.classes/lib/classes/invoice/Invoice';
import { IReadTicket } from 'greenzonegateway.classes/lib/classes/tickets/interfaces/IReadTicket';
import { onBeforeMount, ref, watch, defineProps } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const _route = useRoute();
const _router = useRouter();
const customerStore = useCustomerStore();

const _props = defineProps({ customerId: { type: String } });

const _loadingSubscription = ref(false);
const _showFilterMenu = ref(false);
const _fab = ref<any>(null);

const _service = new BaseService();
const _isCustomersFormValid = ref(false);
const _selectedCustomer = ref({});
const _topServiceItems = ref<IReadServiceItem[]>([]);

const _recentSites = ref<ISite[]>([]);
const _recentInvoices = ref<IInvoice[]>([]);
const _recentTickets = ref<IReadTicket[]>([]);
const _recentDocuments = ref([]);
const _complianceEnums = ref([21, 20]);

const _isLoading = ref(false);
const _isCombinedDocumentsLoading = ref(false);
const _isRecentSitesLoading = ref(false);
const _isRecentInvoicesLoading = ref(false);
const _isRecentServiceItemsLoading = ref(false);
const _isRecentTicketsLoading = ref(false);
const _isVitalsLoading = ref(false);
const _isIssuesLoading = ref(false);

watch(
	customerStore.getCustomers,
	(current: ICustomer[], previous: ICustomer[]) => {
		if (current.length) {
			_selectedCustomer.value = customerStore.getCustomers.filter(
				(x: any) => Number(x.CustomerId) === Number(_props.customerId)
			)[0];
		}
	}
);

onBeforeMount(() => {
	_loadingSubscription.value = isLoading$.subscribe((isLoading: boolean) => {
		_isLoading.value = isLoading;
	});

	if (_route.params.customerId) {
		GetData();
	}
});

function GetData() {
	_showFilterMenu.value = false;
	if (_props.customerId) {
		_router.replace({
			params: {
				customerId: _props.customerId?.toString(),
			},
		});
	}

	GetServiceItems();
	GetSites();
	GetInvoices();
	GetTickets();
	GetCombinedDocuments();
}

function GetServiceItems(): void {
	_topServiceItems.value = [];
	_isRecentServiceItemsLoading.value = true;
	_service
		.Get<IReadServiceItem[]>(
			`ServiceItems/${_props.customerId}?take=5&includeExtras=true`
		)
		.then((serviceItems: IReadServiceItem[]) => {
			_topServiceItems.value = serviceItems;
		})
		.finally(() => {
			_isRecentServiceItemsLoading.value = false;
		});
}

function GetSites(): void {
	_recentSites.value = [];
	_isRecentSitesLoading.value = true;
	_service
		.Get<ISite[]>(`Sites/Customer/${_props.customerId}?take=5`)
		.then((sites: ISite[]) => {
			_recentSites.value = sites;
		})
		.finally(() => {
			_isRecentSitesLoading.value = false;
		});
}

function GetInvoices(): void {
	_recentInvoices.value = [];
	_isRecentInvoicesLoading.value = true;
	_service
		.Get<IInvoice[]>(`Invoices/Customer/${_props.customerId}?take=5`)
		.then((invoices: IInvoice[]) => {
			_recentInvoices.value = invoices;
		})
		.finally(() => {
			_isRecentInvoicesLoading.value = false;
		});
}

function GetTickets(): void {
	_recentTickets.value = [];
	_isRecentTicketsLoading.value = true;
	_service
		.Get<IReadTicket[]>(`Tickets/Customer/${_props.customerId}?take=5`)
		.then((tickets: IReadTicket[]) => {
			_recentTickets.value = tickets;
		})
		.finally(() => {
			_isRecentTicketsLoading.value = false;
		});
}

function GetCombinedDocuments(): void {
	_recentDocuments.value = [];
	_isCombinedDocumentsLoading.value = true;
	_service
		.Get<any[]>(`Documents/Combined/${_props.customerId}?take=5`)
		.then((documents: any[]) => {
			_recentDocuments.value = documents;
		})
		.finally(() => {
			_isCombinedDocumentsLoading.value = false;
		});
}

function Navigate(
	name: string,
	query: any = null,
	params: any = null,
	isExternal = false
): void {
	if (!isExternal) {
		_router.push({
			name: name,
			query: query,
			params: params,
		});
	} else {
		_router.push({ path: name, query: query });
	}
}
function OnDocumentClicked(doc: any) {
	if (doc.FileLocation) {
		let url;
		if (_complianceEnums.value.includes(doc.DocumentTypeId)) {
			url = `Documents/Download/Compliance/${doc.DocumentId}`;
		} else {
			url = `Documents/Download/${doc.DocumentId}`;
		}

		_service.Get<any>(url).then((res: string) => {
			window.open(res, '_blank');
		});
	} else {
		_service
			.GetAxios(`Documents/Download/${doc.DocumentId}`, '', {
				responseType: 'blob',
			})
			.then((response: any) => {
				const fileType = doc.Extension;
				const name = doc.Filename.split('.')[0];
				const href = URL.createObjectURL(response.data);
				const link = document.createElement('a');

				link.href = href;
				link.setAttribute('download', name + '.' + fileType);
				document.body.appendChild(link);
				link.click();
			});
	}
}
function GoToWTNs() {
	_router
		.push(`customers/wasteTransferNotes/${_props.customerId}`)
		.catch((err) => console.log(err));
}
</script>
