import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

import { onBeforeMount } from 'vue';
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';;
import { GZLoadingData } from '@gz/quasar-components-vue3';
import { userStore } from '@/store/users';

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const $q = useQuasar();
onBeforeMount(() => {
	userStore().InitialiseSubscriptions();
	const storedTheme = localStorage.getItem('dark_theme');
	if (storedTheme === 'true') {
		if (storedTheme === 'true') {
			$q.dark.set(true);
		} else {
			$q.dark.set(false);
		}
	}
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, null, {
    default: _withCtx(() => [
      _createVNode(_component_q_page_container, { class: "internal-container" }, {
        default: _withCtx(() => [
          (!_unref(userStore)().IsLoggedIn)
            ? (_openBlock(), _createBlock(_unref(GZLoadingData), {
                key: 0,
                loadingDataText: 
					!_unref(userStore)().IsLoggedIn
						? 'Please Login Using the Popup Provided'
						: 'Logging In...'
				,
                project: 'Customers'
              }, null, 8, ["loadingDataText"]))
            : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})