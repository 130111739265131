import { createRouter, createWebHistory, RouteLocation } from 'vue-router';

const routes = [
	{
		path: '/customersv3/',
		name: 'home',
		component: () =>
			import(
				/* webpackChunkName: "homeview" */ '../views/ViewCustomers/ViewCustomers.vue'
			),
	},
	{
		path: '/customersv3/:customerId?/sites/:siteId?',
		name: 'Sites',
		component: () =>
			import(/* webpackChunkName: "sites" */ '../views/Sites/ViewSites.vue'),
		// props: true,
		props: (route: RouteLocation) => ({
			customerId: route.params.customerId,
			siteId: route.params.siteId,
		}),
	},
	{
		path: '/customersv3/:customerId?',
		name: 'CustomerDetails',
		props: (route: RouteLocation) => ({
			customerId: route.params.customerId,
		}),
		component: () =>
			import(
				/* webpackChunkName: "customerdetails" */ '../views/Details/CustomerDetails.vue'
			),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
