<template>
	<div class="internal-container">
		<q-toolbar class="bg-accent text-white q-pl-none">
			<q-toolbar-title style="padding-left: 16px">Sites</q-toolbar-title>
			<!-- <CustomersSearchFilter @textSearch="FilterCustomers" @filter="ResetSelected" /> -->
		</q-toolbar>

		<!-- <v-app-bar color="primary darken-2" dark>
			<v-toolbar-title>Sites</v-toolbar-title>
			<v-spacer></v-spacer>
			<div style="display: flex" class="mr-n3">
				<v-text-field
					style="align-self: center"
					label="Search"
					placeholder="Filter Data"
					persistent-placeholder
					@input="_delayedFilterSites"
					@click:clear="FilterSites"
					:disabled="_isSitesLoading"
					v-model="_search"
					outlined
					dense
					clearable
					hide-details="auto"
				></v-text-field>
			</div>
		</v-app-bar>

		<div class="pa-4 layout-container" fluid>
			<v-row style="height: 100%">
				<v-col cols="12" class="pb-0" style="position: relative; height: 100%">
					<DataTableWithSlideout
						style="height: calc(100%); position: relative; width: 100%"
						:headers="_headers"
						:items="_sites"
						:itemKey="'SiteId'"
						:menuItems="_menuItems"
						:customSlots="_slots"
						v-model="_selectedSites"
						:isLoading="_isSitesLoading"
						:showGroupBy="false"
					>
					</DataTableWithSlideout>
				</v-col>
			</v-row>
			<DraggableModal
				v-if="_shouldDisplayChangeSiteModal"
				class="elevation-6 pa-0"
				style="
					position: fixed !important;
					top: 50%;
					left: 50%;
					width: 50%;
					z-index: 1000;
					height: calc(var(--header-bar-height-small) * 4);
					transform: translate(-50%, -50%);
				"
			>
				<template #header>
					<v-toolbar dark color="primary" dense flat>
						<v-toolbar-title>Change Site Customer</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-btn @click="_shouldDisplayChangeSiteModal = false" icon
							><v-icon>mdi-close</v-icon></v-btn
						>
					</v-toolbar>
				</template>

				<template #main>
					<v-card id="change-site" class="px-2 py-2 overflow-y-auto" fluid>
						<v-container>
							<v-form ref="changeSiteForm">
								<v-row>
									<v-col class="px-1" cols="6">
										<GenericSelect
											class=""
											id="Selected-Site"
											v-model="_selectedSites"
											v-model:searchItems="_sites"
											:type="'Site'"
											:listName="'SiteName'"
											:listSubName="'SiteReference'"
											:multiple="true"
											:clearable="true"
											:disabled="true"
										>
										</GenericSelect>
									</v-col>
									<v-col class="px-1" cols="6">
										<GenericSelect
											:disabled="!_customers.length || _isCustomersLoading"
											class=""
											id="Select-Customer"
											v-model="_selectedCustomer"
											v-model:searchItems="_customers"
											:type="'Customer'"
											:listName="'CustomerName'"
											:listSubName="'AccountNumber'"
											:listValue="'CustomerId'"
											:multiple="false"
											:loading="_isCustomersLoading"
											:smallChips="true"
											:clearable="true"
											:rules="[(v) => !!v || 'Customer is required']"
										>
										</GenericSelect>
									</v-col>
								</v-row>
								<v-row>
									<v-col class="px-1">
										<DatePickerMenu
											class=""
											v-model="_startDate"
											:label="'Start Date'"
										></DatePickerMenu>
									</v-col>
								</v-row>
							</v-form>
						</v-container>

						<v-footer class="pa-2 pt-0" style="background: none">
							<v-spacer></v-spacer>
							<v-btn color="primary" @click="ValidateChangeSite()" dark :loading="_isLoading"
								>update</v-btn
							>
							<ConfirmDialog
								v-model="_shouldDisplayChangeSiteDialog"
								title="Warning"
								@cancel="
									() => {
										_selectedCustomer = null;
										_shouldDisplayChangeSiteDialog = false;
										_shouldDisplayChangeSiteModal = false;
									}
								"
								@confirm="ChangeSiteCustomer()"
								toolbarIcon="mdi mdi-alert"
								iconColour="warning"
								colour="white"
								:maxWidth="'20%'"
								:isValid="true"
								:loading="_isChangeSitesLoading"
							>
								<template v-slot:extend>
									<v-card-text class="pt-2"
										>This action will move the following sites: <br />
										<br />
										<b>Sites</b>

										<span
											v-for="(site, i) in _selectedSites
												.map((x, i) => {
													let res = x.SiteName;
													if (x.SiteId && i === 2) {
														res += ` - ${x.SiteId}  ${
															_selectedSites.length > 3 ? ' + ' + (_selectedSites.length - 3) : ''
														}`;
														return res;
													} else if (x.SiteId && i <= 1) {
														res += ` - ${x.SiteId}, `;
														return res;
													}
												})
												.slice(0, 3)"
											:key="i"
											><br />{{ site }}</span
										>
										<br />
										<br />

										to the following customer: <br />
										<br />
										<b>Customer</b><br />
										{{ _selectedCustomer?.CustomerName }}.<br /><br />
										Are you sure you want to continue?</v-card-text
									>
								</template>
							</ConfirmDialog>
						</v-footer>
					</v-card>
				</template>
			</DraggableModal>
			<DraggableModal
				v-if="_shouldDisplaySiteContactsModal"
				class="elevation-6 pa-0"
				style="
					position: fixed !important;
					top: 50%;
					left: 50%;
					width: 50%;
					z-index: 1000;
					height: auto;
					transform: translate(-50%, -50%);
				"
			>
				<template #header>
					<v-toolbar dark color="primary" dense flat>
						<v-toolbar-title>Site Contacts</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-btn @click="_shouldDisplaySiteContactsModal = false" icon
							><v-icon>mdi-close</v-icon></v-btn
						>
					</v-toolbar>
				</template>

				<template #main>
					<v-card
						id="audit-items"
						style="height: calc(100% - var(--header-bar-height-small))"
						class="px-2 py-2 overflow-y-auto"
						fluid
					>
						<v-container>
							<v-data-table
								:headers="_contactsHeaders"
								:items="_contacts"
								:items-per-page="10"
								:loading="_isContactsLoading"
								class="elevation-1"
								:single-select="true"
								:hide-default-footer="true"
								v-model="_selectedContact"
								@click:row="(item) => ShowContactEditDialog(item)"
							>
								<template v-slot:[`item.FullName`]="{ item }">
									<tr>
										<td>
											<p class="ma-0 text--primary" :id="`container-${item.Id}`">
												{{ item.FullName }}
												<v-tooltip :attach="`#container-${item.Id}`" top>
													<template v-slot:activator="{ on, attrs }">
														<v-icon v-on="on" v-bind="attrs" v-if="!item.SiteId"
															>mdi-swap-horizontal-bold</v-icon
														>
													</template>
													<span>Shared Contact</span>
												</v-tooltip>
											</p>
										</td>
									</tr>
								</template>
								<template v-slot:[`item.AddressLine1`]="{ item }">
									<tr>
										<p class="ma-0 text--secondary">
											<strong>
												{{ item.AddressLine1 }}
											</strong>
										</p>
										<p class="ma-0 text--secondary">
											<i>{{ item.AddressLine2 }}</i>
										</p>
										<p class="ma-0 text--secondary">
											<i>{{ item.Postcode }}</i>
										</p>
									</tr>
								</template>

								<template v-slot:[`item.Email`]="{ item }">
									<tr>
										<p class="ma-0 text--secondary" v-if="item.Email">
											<strong>
												{{ item.Email }}
											</strong>
										</p>
										<p class="ma-0 text--secondary" v-if="item.Telephone">
											<i>{{ item.Telephone }}</i>
										</p>
										<p class="ma-0 text--secondary" v-if="item.Telephone">
											<i>{{ item.Mobile }}</i>
										</p>
									</tr>
								</template>
							</v-data-table>
							<v-footer absolute style="background: none; pointer-events: none" padless>
								<v-spacer></v-spacer>
								<v-btn
									@click="OnAddContactClicked()"
									style="pointer-events: all"
									fab
									dark
									color="primary"
									class="ma-2"
								>
									<v-icon>mdi-plus</v-icon>
								</v-btn>
							</v-footer>
						</v-container>
						<CreateContactDialog
							@cancel="CancelCreateContact()"
							@contactCreated="(contact) => OnNewContactCreated(contact)"
							:newContact="_contact"
							:title="_AddEditContactDialogTitle"
							:service="_service"
							:customerId="customerId"
							:selectedSite="_selectedSites[0]"
							:value="_shouldDisplayAddContactDialog"
						>
						</CreateContactDialog>
					</v-card>
				</template>
			</DraggableModal>
			<AddEditSiteModal
				:site="_newSite"
				:service="_service"
				:customer="_addSiteCustomer"
				v-model:value="_shouldDisplayAddEditSiteModal"
				@succeeded="OnSiteUpdated"
			></AddEditSiteModal>
		</div>
		<v-speed-dial
			fixed
			v-model="_fab"
			bottom
			right
			direction="top"
			:open-on-hover="true"
			:transition="'slide-y-reverse-transition'"
		>
			<template v-slot:activator>
				<v-btn v-model="_fab" color="primary darken-2" dark fab>
					<v-icon v-if="_fab"> mdi-close </v-icon>
					<v-icon v-else> mdi-dots-vertical </v-icon>
				</v-btn>
			</template>
			<v-tooltip value="true" left color="secondary" nudge-top="13" z-index="20">
				<template v-slot:activator="{}">
					<v-btn color="secondary" @click="OnAddSiteClicked()" fab dark small>
						<v-icon>mdi-map-marker-plus</v-icon>
					</v-btn>
				</template>
				<span>Add Site</span>
			</v-tooltip>
		</v-speed-dial>
		<EmailModal
			v-if="_showEmailView"
			:title="'New Email'"
			:service="_service"
			:outgoingEmailTo="_outgoingEmailCompute"
			:emailAttachments="[]"
			:outgoingEmailCC="[]"
			:isNewEmail="true"
			:isSupplierEmail="false"
			:emailType="_emailTypeEnum.Outgoing"
			:emailUrl="`/Emails/Send`"
			:emailService="_emailService"
			:outgoingEmail="_email"
			@success="ResetEmail()"
			@close="() => (_showEmailView = false)"
			:customerId="Number(customerId)"
			:siteId="Number(_selectedSites[0].SiteId)"
			:shouldDisbaleTemplatesAndAi="true"
		></EmailModal> -->
	</div>
</template>

<style lang="scss" scoped>
@import 'Sites.scss';
</style>

<script setup lang="ts">
import { LoadingData, NoData } from '@gz/styleguide';
import { isLoading$ } from '@gz/api';
import { BaseService } from '@/services/BaseService';
import {
	Email,
	ICustomer,
	ISite,
	MenuItem,
	SlotItem,
} from 'greenzonegateway.classes/lib/classes';
import moment from 'moment';
import {
	CreateSiteDTO,
	IReadSiteDTO,
	ReadSiteDTO,
} from 'greenzonegateway.classes/lib/classes/customer/crud';
import { EmailTypeEnum } from 'greenzonegateway.classes/lib/classes/Generic/EmailType';
import { EmailService } from '@/services/EmailService';
import { CreateContact } from 'greenzonegateway.classes/lib/classes/customer/Contact';
import {
	computed,
	getCurrentInstance,
	nextTick,
	onBeforeMount,
	ref,
	watch,
	defineProps,
} from 'vue';
import { useRouter } from 'vue-router';
import { debounce } from 'quasar';

const _props = defineProps({ customerId: String, siteId: String });

const _emailService = new EmailService();
const _service = new BaseService();
const _router = useRouter();

const _delayedFilterSites = debounce(FilterSites, 500);

const _emailTypeEnum = EmailTypeEnum;

const instance = getCurrentInstance();
const createContactForm = ref();
const changeSiteForm = ref();

const _search = ref('');
const _isLoading = ref(false);
const _loadingSubscription = ref();
const _fab = ref(false);
const _isSitesLoading = ref(false);
const _sites = ref<IReadSiteDTO[]>([]);
const _sitesBackup = ref<IReadSiteDTO[]>([]);
const _selectedSites = ref<IReadSiteDTO[]>([]);
const _isCustomersLoading = ref(false);
const _customers = ref<ICustomer[]>([]);
const _selectedCustomer = ref<ICustomer | null>(null);
const _addSiteCustomer = ref<ICustomer | null>(null);
const _shouldDisplayChangeSiteModal = ref(false);
const _shouldDisplayChangeSiteDialog = ref(false);
const _isChangeSitesLoading = ref(false);
const _shouldDisplayAddEditSiteModal = ref(false);
const _addEditSiteTitle = ref('');
const _isAddSite = ref(false);
const _tab = ref(null);
const _newSite = ref(new ReadSiteDTO());
const _updateSite = ref<any>({});
const _isAddSiteLoading = ref(false);
const _showEmailView = ref(false);
const _eire = ref(false);
const _email = ref(new Email());
const _shouldDisplaySiteContactsModal = ref(false);
const _contacts = ref<any[]>([]);
const _isContactsLoading = ref(false);
const _selectedContact: any = ref({});
const _contact = ref(new CreateContact());
const _shouldDisplayAddContactDialog = ref(false);
const _AddEditContactDialogTitle = ref('');
const _startDate = ref(moment().format('YYYY-MM-DD'));
const _headers = ref([
	{
		text: 'Name',
		value: 'SiteName',
		width: 'auto',
		groupable: false,
	},
	{
		text: 'Reference',
		value: 'SiteReference',
		width: 'auto',
		groupable: false,
	},
	{
		text: 'Address',
		value: 'Address.AddressLine1',
		width: 'auto',
		groupable: false,
	},
	{
		text: 'Contact',
		value: 'Address.Email',
		width: 'auto',
		slot: true,
		groupable: false,
	},
	{
		text: 'Live Services',
		value: 'LiveServices',
		width: 'auto',
		groupable: false,
	},
]);

const _contactsHeaders = ref([
	{
		text: 'Full Name',
		align: 'start',
		sortable: false,
		value: 'FullName',
	},
	{
		text: 'Address',
		align: 'start',
		sortable: false,
		value: 'AddressLine1',
		width: '30%',
	},
	{
		text: 'Contact',
		align: 'start',
		sortable: false,
		value: 'Email',
		width: 'auto',
	},
]);

const _slots = ref<SlotItem[]>([
	{
		Name: 'Address.AddressLine1',
		Line1: 'Address.AddressLine1',
		Line2: 'Address.County',
		Line3: 'Address.Town',
		Line4: 'Address.Postcode',
	},
	{
		Name: 'Address.Email',
		Line1: 'Address.Email',
		Line2: 'Address.Telephone',
		Line3: 'Address.Mobile',
		Line4: 'Address.Work',
	},
]);
const _menuItems = ref<MenuItem[]>([
	{
		Name: 'Email Supplier',
		Callback: OnEmailSupplierClicked,
		Disabled: false,
		ShowCount: false,
		Confirm: () => '',
	},
	{
		Name: 'Web Access',
		Callback: GoToWebAccess,
		Disabled: false,
		ShowCount: false,
		Confirm: () => '',
		Colour: 'var(--v-error-darken1) !important',
	},
	{
		Name: 'Edit Site',
		Callback: OnEditSiteClicked,
		Disabled: false,
		ShowCount: false,
		Confirm: () => '',
		Colour: 'var(--v-error-darken1) !important',
	},
	{
		Name: 'Change Customer',
		Callback: ShowChangeSiteModal,
		Disabled: false,
		ShowCount: false,
		Confirm: () => '',
		Colour: 'var(--v-error-darken1) !important',
	},
	{
		Name: 'Site Contacts',
		Callback: ShowSiteContactModal,
		Disabled: false,
		ShowCount: false,
		Confirm: () => '',
		Colour: 'var(--v-error-darken1) !important',
	},
]);

watch(_eire, (current: boolean) => {
	if (current) {
		_updateSite.value.Postcode = 'eire';
		_newSite.value.Address.Postcode = 'eire';
	}
});

watch(_selectedSites, (newValue: any) => {
	let items = _menuItems.value.filter(
		(xx) => xx.Name === 'Web Access' || 'Edit Site'
	);
	if (newValue.length > 1) {
		if (items) {
			items.forEach((item) => (item.Disabled = true));
		}
	} else {
		if (items) {
			items.forEach((item) => (item.Disabled = false));
		}
	}
});

const _outgoingEmailCompute = computed(() => {
	let outgoing: string[] = [];
	if (_selectedSites.value[0].Address?.Email) {
		outgoing.push(..._selectedSites.value[0].Address.Email?.split(/[;,]/));
	}
	if (!_selectedSites.value[0].Customer?.CustomerAddress?.Email) {
		outgoing.push(
			..._selectedSites.value[0].Customer.CustomerAddress.Email?.split(/[;,]/)
		);
	}
	return outgoing;
});

onBeforeMount(async () => {
	_loadingSubscription.value = isLoading$.subscribe((isLoading: boolean) => {
		_isLoading.value = isLoading;
	});
	if (_props.customerId) {
		await GetSites();
		await GetCustomers();
	}
});

function ShowChangeSiteModal() {
	_shouldDisplayChangeSiteModal.value = true;
	_selectedCustomer.value = null;
	Focus();
}

async function GetSites() {
	_isSitesLoading.value = true;
	_sites.value = [];
	Focus();

	await _service
		.Get<IReadSiteDTO[]>(`Sites/Customer/${_props.customerId}`)
		.then((sites: IReadSiteDTO[]) => {
			_sites.value = sites;
			_sitesBackup.value = sites;
			_isSitesLoading.value = false;

			if (_props.siteId) {
				_newSite.value =
					sites.find((xx) => Number(xx.SiteId) === Number(_props.siteId)) ??
					new ReadSiteDTO();
				_shouldDisplayAddEditSiteModal.value = true;
			}
		});
}
function OnEmailSupplierClicked() {
	_showEmailView.value = true;
}

function OnAddContactClicked() {
	_shouldDisplayAddContactDialog.value = true;
	_AddEditContactDialogTitle.value = 'Add Contact';
}
function OnNewContactCreated(contact: any) {
	_shouldDisplayAddContactDialog.value = false;
	contact = new CreateContact();
	_contacts.value.push(contact);
	(createContactForm as any).resetValidation();
}
async function ShowSiteContactModal() {
	_shouldDisplaySiteContactsModal.value = true;
	_shouldDisplayAddContactDialog.value = false;
	GetContactsForSite();
}

async function GetContactsForSite() {
	_isContactsLoading.value = true;
	await _service
		.Get<any[]>(
			`Contacts?customerId=${_props.customerId}&siteId=${_selectedSites.value[0].SiteId}`
		)
		.then((contacts: any[]) => {
			_contacts.value = contacts;
			_isContactsLoading.value = false;
		});
}

function FilterSites(search: string) {
	if (!search) {
		_sites.value = _sitesBackup.value;
	}
	_sites.value = _sitesBackup.value.filter(
		(site) =>
			site.SiteId?.toString().includes(search) ||
			site.CustomerId?.toString().includes(search) ||
			site.SiteReference?.toString().includes(search) ||
			site.SiteName?.toLowerCase().includes(search.toLowerCase()) ||
			site.SiteStatus?.toLowerCase().includes(search.toLowerCase()) ||
			site.Address?.AddressLine1?.toLowerCase().includes(
				search.toLowerCase()
			) ||
			site.Address?.AddressLine2?.toLowerCase().includes(
				search.toLowerCase()
			) ||
			site.Address?.Town?.toLowerCase().includes(search.toLowerCase()) ||
			site.Address?.Email?.toLowerCase().includes(search.toLowerCase()) ||
			site.Address?.Postcode?.toLowerCase().includes(search.toLowerCase()) ||
			site.Address?.Telephone?.includes(search) ||
			site.LiveServices?.toString().includes(search)
	);
}

function ResetEmail() {
	_showEmailView.value = false;
	_email.value = new Email();
}

function ValidateChangeSite() {
	if ((changeSiteForm as any).validate()) {
		_shouldDisplayChangeSiteDialog.value = true;
	}
}

function OnAddSiteClicked() {
	_updateSite.value = {};
	_newSite.value = new ReadSiteDTO();
	_isAddSite.value = true;
	_shouldDisplayAddEditSiteModal.value = true;
}

function OnEditSiteClicked() {
	_isAddSite.value = false;
	_newSite.value = JSON.parse(JSON.stringify(_selectedSites.value[0]));
	_updateSite.value = {};
	_shouldDisplayAddEditSiteModal.value = true;
}
function ShowContactEditDialog(contact: any) {
	_shouldDisplayAddContactDialog.value = true;
	_AddEditContactDialogTitle.value = 'Edit Contact';
	_contact.value = contact;
}

function ChangeSiteCustomer() {
	if (!_isChangeSitesLoading.value) {
		let request = {
			customerId: Number(_selectedCustomer.value?.CustomerId),
			siteIds: _selectedSites.value.map((site: IReadSiteDTO) => site.SiteId),
			FromDate: _startDate.value,
		};
		_isChangeSitesLoading.value = true;
		_shouldDisplayChangeSiteDialog.value = false;
		_service
			.Post<any>('Sites/Update/Customer', request)
			.then(() => {
				_selectedSites.value.forEach((site: IReadSiteDTO) => {
					const index = _sites.value.indexOf(site);
					_sites.value.splice(index, 1);
					_selectedSites.value = [];
					_selectedCustomer.value = null;
				});
			})
			.finally(() => {
				_shouldDisplayChangeSiteModal.value = false;
				_isChangeSitesLoading.value = false;
			});
	}
}

function Focus() {
	nextTick(() => {
		instance?.proxy?.$forceUpdate();
	});
}
function GoToWebAccess() {
	_router.push({
		name: 'WebAccess',
		query: {
			customerId: _props.customerId,
			siteId: _selectedSites.value[0].SiteId?.toString(),
		},
	});
}
async function GetCustomers() {
	_isCustomersLoading.value = true;
	_service
		.Get<ICustomer[]>('Customers/List')
		.then((customers) => {
			_customers.value = customers;
			_addSiteCustomer.value = customers.filter(
				(x) => x.CustomerId == _props.customerId
			)[0];
		})
		.finally(() => {
			_isCustomersLoading.value = false;
		});
}
function CancelCreateContact() {
	_shouldDisplayAddContactDialog.value = false;
	_contact.value = new CreateContact();
	(createContactForm as any).resetValidation();
}
function OnSiteUpdated(site: IReadSiteDTO) {
	if (_isAddSite.value) {
		_sites.value.unshift(site);
	} else {
		_sites.value = _sites.value.map((s) =>
			s.SiteId !== site.SiteId ? s : site
		);
	}
}
</script>
