<template>
	<td>
		<div>
			<slot name="line1"></slot>
		</div>
		<div>
			<slot name="line2"></slot>
		</div>
		<div>
			<slot name="line3"></slot>
		</div>
	</td>
</template>

<style></style>

<script setup lang="ts"></script>
