import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { BaseService } from '@/services/BaseService';
import {
	Customer,
	CustomerStatusEnum,
	ICustomer,
	PaymentMethodEnum,
} from 'greenzonegateway.classes/lib/classes';
import {
	CustomerDetailsView,
	ICustomerDetailsView,
} from 'greenzonegateway.classes/lib/classes/customer/CustomerDetailsView';

export const useCustomerStore = defineStore('customer', () => {
	//Services
	const service = new BaseService();
	const customersPromise = ref();
	const customersViewPromise = ref();
	const customer = ref<ICustomer>(new Customer());
	const customers = ref([] as ICustomer[]);
	const customersBackup = ref([] as ICustomer[]);
	const customerSearchFilters = ref();

	const customersView = ref([] as ICustomerDetailsView[]);
	const customersViewBackup = ref([] as ICustomerDetailsView[]);
	const customerStatuses = ref([] as any[]);
	const paymentMethods = ref([] as any[]);
	const selectedCutsomerStatus = ref([] as any[]);
	const selectedPaymentMethods = ref([] as any[]);

	let isLoading = false;

	function loadEnums() {
		customerStatuses.value = fixCasing(convertEnumToList(CustomerStatusEnum));
		paymentMethods.value = convertEnumToList(PaymentMethodEnum);

		selectedCutsomerStatus.value.push(
			customerStatuses.value.find((value) => value.Name === 'Live')
		);
		selectedCutsomerStatus.value.push(
			customerStatuses.value.find(
				(value) => value.Name === 'Pending Termination'
			)
		);
		selectedCutsomerStatus.value.push(
			customerStatuses.value.find((value) => value.Name === 'Stop')
		);
	}

	function fixCasing(items: any[]) {
		items.forEach((item) => {
			item.Name = item.Name.split(' ')
				.map((word: string) => {
					return word.charAt(0) + word.slice(1).toLowerCase();
				})
				.join(' ');
		});

		return items;
	}

	function convertEnumToList(rawEnum: any) {
		const val = Object.keys(rawEnum)
			.filter((v) => isNaN(Number(v)))
			.map((Name: any) => {
				const val = rawEnum[Name as keyof typeof rawEnum];
				Name = Name?.match(/DD|[A-Z][a-z]+|[0-9]+/g)?.join(' ') ?? Name;

				return {
					Value: val,
					Name,
				};
			})
			.sort((a, b) => a.Name.localeCompare(b.Name));
		return val;
	}

	function updateCustomersList() {
		isLoading = true;
		customersPromise.value = service.Get<ICustomer[]>('Customers/List');

		customersPromise.value.then((usersInternal: ICustomer[]) => {
			customers.value = usersInternal;
			customersBackup.value = usersInternal;
			isLoading = false;
		});
	}

	function searchByFilters() {
		const ProcessedFilters: any = {};

		ProcessedFilters.CustomerStatus = selectedCutsomerStatus.value.map(
			(val) => {
				return val.Value;
			}
		);
		ProcessedFilters.PaymentMethods = selectedPaymentMethods.value.map(
			(val) => {
				return val.Value;
			}
		);

		customerSearchFilters.value = ProcessedFilters;
		customersViewPromise.value = service.Post<ICustomerDetailsView[]>(
			'Customers/List/Details',
			customerSearchFilters.value
		);
		customersViewPromise.value.then((usersInternal: ICustomerDetailsView[]) => {
			customersView.value = usersInternal;
			customersViewBackup.value = usersInternal;
		});

		isLoading = false;
	}

	function getCustomerFromId(customerId: any) {
		const CustomerIdNumeric = parseInt(customerId);

		if (!customerId) {
			return;
		}
		if (customer.value.CustomerId === CustomerIdNumeric) {
			return customer.value;
		}
		if (customers.value.length) {
			const customerIndex = customers.value.findIndex((x: ICustomer) => {
				return x.CustomerId === CustomerIdNumeric;
			});

			//Fallback against things missing from the customers array.
			if (customerIndex === -1) {
				getCustomerFromIdAPI(customerId);
			}

			customer.value = customers.value[customerIndex];

			return customer.value;
		} else {
			getCustomerFromIdAPI(customerId);
		}
	}

	function getCustomerFromIdAPI(customerId: number) {
		service
			.Get<ICustomer>(`Customers/Details/${customerId}`)
			.then((cust: ICustomer) => {
				customer.value = cust;
				return cust;
			});
	}

	function loadInitialCustomersList() {
		if (customersView.value.length === 0 && !isLoading) {
			isLoading = true;
			if (
				customerStatuses.value.length === 0 ||
				paymentMethods.value.length === 0
			) {
				loadEnums();
			}
			searchByFilters();
		}
	}

	const getCustomers = computed(() => {
		if (customers.value.length === 0 && !isLoading) {
			isLoading = true;
			updateCustomersList();
		}
		return customers.value;
	});

	const getCustomersView = computed((): CustomerDetailsView[] => {
		return customersView.value as CustomerDetailsView[];
	});

	return {
		getCustomers,
		getCustomersView,
		getCustomerFromId,
		getCustomerFromIdAPI,
		updateCustomersList,
		searchByFilters,
		customersPromise,
		customerStatuses,
		paymentMethods,
		selectedCutsomerStatus,
		selectedPaymentMethods,
		loadEnums,
		loadInitialCustomersList,
	};
});
