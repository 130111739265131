<template>
	<div class="home internal-container" style="width: 100%; height: 100%">
		<q-toolbar class="bg-accent text-white q-pl-none">
			<q-toolbar-title style="padding-left: 16px"
				>All Customers</q-toolbar-title
			>
			<!-- <CustomersSearchFilter @textSearch="FilterCustomers" @filter="ResetSelected" /> -->
		</q-toolbar>

		<div class="pa-4 layout-container" style="height: calc(100% - 128px)" fluid>
			<div style="height: 100%; overflow-y: auto">
				<q-page-sticky
					position="bottom-right"
					style="margin: 8px !important; z-index: 1001"
					:offset="[24, 24]"
				>
					<q-fab
						color="primary"
						v-model="_fab"
						vertical-actions-align="left"
						direction="up"
					>
						<template #active-icon>
							<q-icon
								style="padding: none !important"
								name="mdi-close"
							></q-icon>
						</template>
						<template #icon>
							<q-icon class="q-pa-none" name="mdi-dots-vertical"></q-icon>
						</template>
						<q-fab-action
							external-label
							label-position="left"
							color="secondary"
							icon="mdi-account-plus-outline"
							label="Create"
							@click="createCustomerModal?.show()"
						/>
						<q-fab-action
							external-label
							label-position="left"
							color="secondary"
							icon="mdi-key-plus"
							label="Create"
							@click="Navigate(`WebAccess`, null, null)"
						/>
						<q-fab-action
							external-label
							label-position="left"
							color="secondary"
							icon="mdi-cash"
							label="Quotes"
							@click="Navigate(`Quotes`, null, null)"
						/>
						<q-fab-action
							external-label
							label-position="left"
							color="secondary"
							icon="mdi-note-text"
							label="Waste Transfer Notes"
							@click="Navigate(`WasteTransferNotes`, null, null)"
						/>
					</q-fab>
				</q-page-sticky>
				<div class="row" style="height: 100%">
					<div class="col" style="height: 100%">
						<GZSlideout
							v-model="_isSlideoutOpen"
							:breakpoint="900"
							:overlay="false"
							drawerWidth="450"
							:pageContainerStyle="{ height: 'calc(100% - 58px)' }"
							:layout-style="{ height: '100%', minHeight: '0px' }"
						>
							<template #menu>
								<q-toolbar
									class="bg-accent"
									style="height: 32px; background-color"
								>
									<div class="row full-width">
										<q-btn
											flat
											dense
											class="q-ma-sm"
											@click="_isSlideoutOpen = false"
										>
											<q-icon
												name="mdi-chevron-double-right"
												color="white"
											></q-icon>
										</q-btn>
										<q-toolbar-title style="padding-top: 8px; color: white">{{
											_selectedCustomer?.CustomerName
										}}</q-toolbar-title>
									</div>
								</q-toolbar>
								<q-scroll-area
									style="height: 80%"
									class="q-pa-md"
									id="side-panel-container"
								>
									<div class="row">
										<div class="col-6 q-pr-md">
											<GZLabelText
												:label="'Contact Name'"
												:text="
													`${_selectedCustomer?.Customer?.Title || ''} ${
														_selectedCustomer?.Customer?.FirstName || ''
													} ${
														_selectedCustomer?.Customer?.LastName || ''
													}`.trim() || 'N/A'
												"
											/>
										</div>

										<div class="col-6">
											<GZLabelText
												class="q-mb-md"
												:label="'Telephone'"
												:text="`${
													_selectedCustomer?.CustomerAddress?.Telephone || 'N/A'
												}`"
											/>
										</div>

										<div class="col-6">
											<GZLabelText
												class="q-mb-md q-pr-md"
												:label="'Email'"
												:text="`${
													_selectedCustomer?.CustomerAddress?.Email || 'N/A'
												}`"
											/>
										</div>
										<div class="col-6">
											<GZLabelText
												class="q-mb-md"
												:label="'Mobile'"
												:text="`${
													_selectedCustomer?.CustomerAddress?.Mobile || 'N/A'
												}`"
											/>
										</div>
										<div class="col-6">
											<GZLabelText
												class="q-mb-md q-pr-md"
												:label="'Payment Method'"
												:text="`${
													_selectedCustomer?.PaymentMethod?.Name || 'N/A'
												}`"
											/>
										</div>

										<div class="col-6">
											<GZLabelText
												class="q-mb-md"
												:label="'Account Manager'"
												:text="`${_selectedCustomer?.AccountManager || 'N/A'}`"
											/>
										</div>

										<div class="col-6">
											<GZLabelText
												class="q-mb-md q-pr-md"
												:label="'Payment Terms'"
												:text="`${_selectedCustomer?.InvoiceTerms} Days`"
											/>
										</div>

										<div
											class="col-12"
											v-if="_selectedCustomer.CustomerAddress"
										>
											<GZLabelText
												:label="'Address'"
												:text="_selectedCustomer.CustomerAddress.AddressLine1"
											/>

											<div
												class="row"
												v-if="_selectedCustomer.CustomerAddress.Town"
											>
												<div class="col">
													<span class="text-subtitle-2">{{
														_selectedCustomer.CustomerAddress.Town
													}}</span>
												</div>
											</div>
											<div
												class="row"
												v-if="_selectedCustomer.CustomerAddress.Postcode"
											>
												<div class="col">
													<span class="text-subtitle-2">{{
														_selectedCustomer.CustomerAddress.Postcode
													}}</span>
												</div>
											</div>
										</div>

										<div class="col">
											<span class="text-subtitle-1"
												><strong>{{
													_selectedCustomer.ProposedPriceIncreaseNote
												}}</strong></span
											>
										</div>
									</div>
								</q-scroll-area>
							</template>
							<template #content>
								<q-table
									style="height: 100%; width: 100%; position: relative"
									:columns="_columns"
									:rows="_customerStore.getCustomersView"
									:rows-per-page-options="[20]"
									:loading="_isLoading"
									:loading-label="'Getting Customers...'"
									v-model:pagination="_pagination"
									row-key="CustomerId"
									hide-pagination
									@row-click="(_, row) => GetCustomer(row)"
									@row-dblclick="
										(_, row) =>
											Navigate('CustomerDetails', null, {
												customerId: row.CustomerId,
											})
									"
								>
									<template #body-cell-id="props">
										<q-td :props="props" class="q-pa-0">
											<GZIndicator
												:color="GetStatusIndicatorColour(props.row)"
												:indicator-wraper-style="'height: 100%'"
												:indicator-style="'height: 100%; position: absolute; top: 0; left: 0; width: 8px;'"
											>
												<q-tooltip>
													<span>{{ props.row.CustomerStatus }}</span>
												</q-tooltip>
											</GZIndicator>
											<b>{{ props.row.AccountNumber }}</b>
										</q-td>
									</template>
									<template #body-cell-name="props">
										<TDMultiline>
											<template #line1>
												<b>{{ props.row.CustomerName }}</b>
											</template>
											<template #line2>
												{{ props.row.Group }}
											</template>
											<template #line3>
												<i
													>{{
														props.row.Town +
														(props.row.Town && props.row.Postcode ? ',' : '')
													}}{{ props.row.Postcode }}</i
												>
											</template>
										</TDMultiline>
									</template>
									<template #body-cell="props">
										<q-td>
											{{ props.value }}
										</q-td>
										<q-menu touch-position context-menu>
											<q-list dense style="min-width: 100px">
												<q-item
													v-for="item in _menuItems"
													:key="item.Id"
													:disable="item.Disabled"
													style="padding: 16px"
													clickable
													v-close-popup
													@click="item.Callback"
												>
													<q-item-section>{{ item.Name }}</q-item-section>
												</q-item>
											</q-list>
										</q-menu>
									</template>
								</q-table>
								<GZPagination
									class="q-pt-sm q-px-sm"
									:items-length="_customerStore.getCustomersView.length"
									v-model:pagination="_pagination"
								/>
							</template>
						</GZSlideout>
					</div>
				</div>
			</div>
		</div>
		<GZDraggableModal ref="createCustomerModal" style="width: 600px">
			<template #header>
				<q-toolbar class="bg-primary text-white">
					<q-toolbar-title>Create Customer</q-toolbar-title>
					<q-space></q-space>
					<q-btn
						@click="_showCreateCustomer = false"
						icon="mdi-close"
						flat
					></q-btn>
				</q-toolbar>
			</template>
			<template #body>
				<q-card
					id="audit-items"
					style="
						height: calc(100% - var(--header-bar-height-small));
						overflow-y: auto;
					"
					class="q-pa-md"
				>
					<q-form ref="customerCreateForm" v-model="_isCreateCustomerValid">
						<q-field
							v-model="_createCustomer.CustomerName"
							class="pa-2"
							style="align-self: center"
							label="Customer Name"
							placeholder="Enter Name..."
							persistent-placeholder
							outlined
							dense
							clearable
							hide-details="auto"
							:rules="[(v) => !!v || 'Name is required']"
						></q-field>
						<q-checkbox
							class="px-2"
							v-model="_createCustomer.ContractCustomer"
							label="Extra?"
						></q-checkbox>

						<q-field
							v-model="_createCustomer.AccountNumber"
							class="pa-2"
							style="align-self: center"
							label="Account Number"
							placeholder="Enter Number..."
							persistent-placeholder
							outlined
							dense
							clearable
							hide-details="auto"
							:rules="[(v) => !!v || 'Name is required']"
							><template #after>
								<q-btn
									color="warning"
									variant="text"
									class="font-weight-bold"
									flat
									>Auto Generate</q-btn
								>
							</template>
						</q-field>
					</q-form>
				</q-card>
			</template>
			<template #footer>
				<div style="text-align: end">
					<q-btn
						:disable="_isLoading || !_isCreateCustomerValid"
						color="primary"
						rounded
						flat
						@click="CreateCustomer()"
					>
						Create
					</q-btn>
				</div>
			</template>
		</GZDraggableModal>
	</div>
</template>

<style lang="scss" scoped>
@import 'Customers.scss';
</style>

<script setup lang="ts">
import { isLoading$ } from '@gz/api';
import { useCustomerStore } from '@/store/customer';
import { BaseService } from '@/services/BaseService';
import { SlotItem } from 'greenzonegateway.classes/lib/classes/Generic/interfaces/SlotItem';
import { CustomerStatusEnum } from 'greenzonegateway.classes/lib/classes/customer/enums/CustomerStatus';
import {
	Customer,
	CustomerDetailsView,
	ICustomer,
} from 'greenzonegateway.classes/lib/classes/customer/index';
import { MenuItem } from 'greenzonegateway.classes/lib/classes';
import CustomersSearchFilter from './Components/CustomersSearchFilter.vue';
import {
	getCurrentInstance,
	nextTick,
	onBeforeUnmount,
	onMounted,
	Ref,
	ref,
	watch,
	computed,
} from 'vue';
import { useRouter } from 'vue-router';
import {
	GZDraggableModal,
	GZIndicator,
	GZSlideout,
	GZLabelText,
	GZPagination,
} from '@gz/quasar-components-vue3';
import TDMultiline from '@/components/TDMultiline.vue';
import { debounce } from 'quasar';

function nameof<T>(name: Extract<keyof T, string>): string {
	return name;
}

const createCustomerModal = ref<typeof GZDraggableModal>();

// eslint-disable-next-line @typescript-eslint/no-var-requires
const _customerStore = useCustomerStore();
const _instance = getCurrentInstance();
const _service = new BaseService();
const _router = useRouter();
const _loadingSubscription = ref();
const _isLoading = ref(true);

// selectedCustomers
const _customerStatusEnum = CustomerStatusEnum;
const _customers: Ref<CustomerDetailsView[]> = ref([]);
const _customersBackup: Ref<CustomerDetailsView[]> = ref([]);
const _selectedCustomers: Ref<CustomerDetailsView[]> = ref([]);
const _selectedCustomer: Ref<ICustomer> = ref(new Customer());

const _createCustomer = ref<any>({});
const _showCreateCustomer = ref(false);
const _isCreateCustomerValid = ref(false);
const _isExtra = ref(false);
const _isSlideoutOpen = ref(false);

const _fab = ref(false);
const _columns = ref([
	{
		name: 'id',
		label: 'No.',
		align: 'left',

		field: nameof<CustomerDetailsView>('CustomerId'),
		style: 'width: 8%',
	},
	{
		name: 'name',
		label: 'Name',
		field: 'CustomerName',
		align: 'left',
		style: 'width: 30%',
	},
	{
		name: 'liveServices',
		label: 'Live Services',
		field: 'LiveServices',
		align: 'left',
		style: 'width: auto',
	},
	{
		name: 'liveSites',
		label: 'Live Sites',
		field: 'LiveSites',
		align: 'left',
		style: 'width: auto',
	},
	{
		name: 'paymentMethod',
		label: 'Payment Method',
		field: 'Method',
		align: 'left',
		style: 'width: auto',
	},
]);
const _pagination = ref({
	sortBy: 'desc',
	descending: false,
	page: 1,
	rowsPerPage: 20,
});

const _slots: Ref<SlotItem[]> = ref([
	{
		Name: 'CustomerName',
		Line1: 'CustomerName',
		Line2: 'Group',
		Line3: 'Town',
		Line4: 'Postcode',
	},
]);

const _menuItems: Ref<MenuItem[]> = ref([
	{
		Name: 'Edit',
		Callback: Edit,
		ShowCount: true,
		Disabled: false,
		Confirm: () => '',
	},
	{
		Name: 'View Sites',
		Callback: NavigateToSites,
		ShowCount: true,
		Disabled: false,
		Confirm: () => '',
	},
	{
		Name: 'View Quotes',
		Callback: NavigateToQuotes,
		ShowCount: false,
		Disabled: false,
		Confirm: () => '',
	},
	{
		Name: 'Manage Suspended Services',
		Callback: NavigateToSuspendedServices,
		ShowCount: true,
		Disabled: false,
		Confirm: () => '',
	},
]);

watch(_selectedCustomers, (newValue: CustomerDetailsView[]) => {
	let item = null;

	if (newValue.length > 1) {
		item = _menuItems.value.find((xx) => xx.Name === 'Edit');

		if (item) {
			item.Disabled = true;
		}
	} else {
		item = _menuItems.value.find((xx) => xx.Name === 'Edit');

		if (item) {
			item.Disabled = false;
		}
	}
});

watch(_customerStore.getCustomersView, (current: CustomerDetailsView[]) => {
	_customers.value = current;
	_customersBackup.value = current;
});

onMounted(async () => {
	_loadingSubscription.value = await isLoading$.subscribe(
		(isLoading: boolean) => {
			_isLoading.value = isLoading;
		}
	);
	_customerStore.loadInitialCustomersList();

	var customers = _customerStore.getCustomersView;

	_customers.value = customers;
	_customersBackup.value = customers;

	nextTick(() => {
		_instance?.proxy?.$forceUpdate();
	});
});

function ResetSelected() {
	_selectedCustomers.value = [];
}

function GetCustomer(customer: CustomerDetailsView) {
	_service
		.Get<ICustomer>(`Customers/Details/${customer.CustomerId}`)
		.then((customer) => {
			nextTick(() => {
				_instance?.proxy?.$forceUpdate();
				_selectedCustomer.value = customer;
			});
			_isSlideoutOpen.value = true;
		});
}

function GetStatusIndicatorColour(item: CustomerDetailsView) {
	if (Object.keys(item).length === 0) {
		return '';
	} else if (item.CustomerStatus.toLowerCase().includes('live')) {
		return 'var(--q-positive)';
	} else if (item.CustomerStatus.toLowerCase().includes('stop')) {
		return 'var(--q-negative)';
	} else if (item.CustomerStatus.toLowerCase().includes('pending')) {
		return 'var(--q-warning)';
	} else if (item.CustomerStatus.toLowerCase().includes('termin')) {
		return 'var(--q-negative-darken2)';
	}
}

function FilterCustomers(search: string) {
	if (typeof search === 'object') {
		_customers.value = _customersBackup.value;
		return;
	}
	_customers.value = _customersBackup.value.filter(
		(xx) =>
			xx.AccountNumber?.toLowerCase().includes(search.toLowerCase()) ||
			xx.CustomerName?.toLowerCase().includes(search.toLowerCase()) ||
			xx.ContractStart?.toString()
				.toLowerCase()
				.includes(search.toLowerCase()) ||
			xx.ContractEnd?.toString().toLowerCase().includes(search.toLowerCase()) ||
			xx.Postcode?.toLowerCase().includes(search.toLowerCase()) ||
			xx.Town?.toLowerCase().includes(search.toLowerCase()) ||
			xx.CustomerId?.toString().includes(search) ||
			xx.Method?.toLowerCase().includes(search.toLowerCase()) ||
			xx.LiveSites?.toString().includes(search) ||
			xx.LiveServices?.toString().includes(search)
	);
}

function Edit() {
	Navigate('Edit Customer Details', null, {
		customerId: _selectedCustomers.value[0].CustomerId,
	});
}

function Navigate(
	name: string,
	query: any = null,
	params: any = null,
	isExternal = false
): void {
	if (!isExternal) {
		_router.push({
			name: name,
			query: query,
			params: params,
		});
	} else {
		_router.push({ path: name, query: query });
	}
}
function NavigateToSites() {
	Navigate('Sites', null, {
		customerId: _selectedCustomers.value[0]?.CustomerId,
	});
}
function NavigateToQuotes() {
	_router.push(`customers/${_selectedCustomers.value[0]?.CustomerId}/quotes`);
}
function NavigateToSuspendedServices() {
	Navigate('SuspendedServices', null, {
		customerId: _selectedCustomers.value[0]?.CustomerId,
	});
}

function CreateCustomer() {
	_service
		.Post<any>('Customers/Create', _createCustomer)
		.then((customerId: any) => {
			_showCreateCustomer.value = false;
			_router.push(`/customers/${customerId}/Edit/CustomerDetails`);
		});
}

onBeforeUnmount(() => {
	if (_loadingSubscription) {
		_loadingSubscription.value.unsubscribe();
	}
});
</script>
